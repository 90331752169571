import React, { useRef, useState } from 'react'
import { MenuItem, useDisclosure, useToast } from '@chakra-ui/react'

import axios from 'axios'
import useSWR from 'swr'


const baseApi = process.env.REACT_APP_BASE_API;

export default function MenuChangeHotCar({ id, tag , mutate }) {
    // console.log(mutate);

    const editsuccess = useToast({
        title: 'เพิ่มข้อมูลสำเร็จ',
        position: 'top-right',
        description: `แก้ไขข้อมูลสำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })

    const editerror = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `แก้ไขข้อมูลไม่สำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })

    const UpdateTagCar = async (id, value, old_value) => {
        if (old_value) {
            await Delete_Tag(old_value)
        }
        try {
            const formData = new FormData();
            formData.append('cars_tag', value);
            await axios.post(`${baseApi}/api/admin/update/cars/${id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    editsuccess()
                    mutate()
                })
        } catch (error) {
            editerror()
        }
    }
    return (
        <>
            <MenuItem
                _hover={{ bg: tag === 'hot' ? '#E53E3E30' : 'whiteAlpha.100' }}
                onClick={
                    () => {
                        if (tag === 'hot') {
                            UpdateTagCar(id, '')
                        } else {
                            UpdateTagCar(id, 'hot')
                        }
                    }
                }
            >
                {
                    tag === 'hot' ?
                        'ยกเลิกการตั้งค่าเป็นรถยอดนิยม'
                        : 'ตั้งค่าเป็นรถยอดนิยม'
                }
            </MenuItem>

        </>
    )
}
