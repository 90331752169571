import { Select } from '@chakra-ui/react'
import React from 'react'

import useSWR from 'swr'


const baseapi = process.env.REACT_APP_BASE_API;

export default function Select_Brand({ ...props }) {
    const fetcher = (url) => fetch(url).then((res) => res.json())
    const { data, error } = useSWR(`${baseapi}/api/store/brands`, fetcher)
    if (error) return <div>failed to load</div>
    if (!data) return <div>loading...</div>
    return (
        <Select placeholder='Choose a brand' _focus={{ outline: 'none' }} {...props} >
            {data.map((brand, idx) => (
                <option key={idx} value={brand.brand_name}>
                    {brand.brand_name}
                </option>
            ))}
        </Select>
    )
}
