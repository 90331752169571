import React, { useRef, useState } from 'react'
import {
    MenuItem, useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useToast
} from '@chakra-ui/react'

import axios from 'axios'
import useSWR from 'swr'
import Select_Status from 'components/Select/Select_Status';
import { useFormik } from 'formik';


const baseApi = process.env.REACT_APP_BASE_API;

export default function MenuChangeStatusCar({ id, status, mutate }) {
    // console.log(status);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    const editsuccess = useToast({
        title: 'เพิ่มข้อมูลสำเร็จ',
        position: 'top-right',
        description: `แก้ไขข้อมูลสำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })
    const editerror = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `แก้ไขข้อมูลไม่สำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })

    const formik = useFormik({
        initialValues: {
            cars_status: status
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
            // console.log(values);
            const updateCarStatus = async (id) => {
                try {
                    const formData = new FormData();
                    formData.append('cars_status', values.cars_status);
                    await axios.post(`${baseApi}/api/admin/update/cars/${id}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: localStorage.getItem('access_token')
                            }
                        }).then((resp) => {
                            // console.log(resp);
                            editsuccess()
                            mutate();
                            onClose();
                        })
                } catch (error) {
                    // console.log(error);
                    editerror()
                    onClose()

                }
            }
            updateCarStatus(id)
        },
    })
    return (
        <>
            <MenuItem
                onClick={onOpen}
            >
                ตั้งค่าสถานะ
            </MenuItem>


            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                size={'xl'}
            >
                <AlertDialogOverlay>
                    <form onSubmit={formik.handleSubmit}>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                กรุณาเลือกสถานะ
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                <Select_Status
                                    name="cars_status"
                                    value={formik.values.cars_status}
                                    onChange={formik.handleChange}
                                />
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    ยกเลิก
                                </Button>
                                <Button
                                    type='submit'
                                    colorScheme='green'
                                    ml={3}
                                >
                                    ยืนยัน
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>

                    </form>
                </AlertDialogOverlay>
            </AlertDialog>

        </>
    )
}
