import { useState } from "react"
import { Box, Stack } from "@chakra-ui/react"
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { Image } from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import useSWR from 'swr'
import { Checkbox, CheckboxGroup, CloseButton } from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'
import { useJwt } from "react-jwt";
import axios from 'axios'

const baseApi = process.env.REACT_APP_BASE_API;

const Test = () => {
    const { decodedToken, isExpired } = useJwt(localStorage.getItem('access_token'));
    const [file, setFile] = useState()
    const [imageList, setImageList] = useState([]);
    const [caption, setCaption] = useState("")
    const [carData, setCarData] = useState([]);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [deleteImage, setDeleteImage] = useState([]);
    const [loading, setLoading] = useState(false);

    const { data, error, isLoading, mutate } = useSWR(`${baseApi}/api/store/cars?size=10&page=0&createDate=true`,
        (url) =>
            axios
                .get(url)
                .then((res) => res.data)
    );

    const testlogin = async (_id) => {
        setLoading(true)
        try {
            const _delete = await axios.post(`${baseApi}/api/user/login`,
                {
                    username: "admin1",
                    password: "password1"
                }
            ).then((resp) => {
                // console.log(resp.data);
                localStorage.setItem('access_token', resp?.data?.access_token);
                localStorage.setItem('refresh_token', resp?.data?.refresh_token);
                localStorage.setItem('user_name', resp?.data?.result?.username);
                localStorage.setItem('user_position', resp?.data?.result?.position);
                setTimeout(() => setLoading(false), 1000)
            })
            // console.log(_delete);
        } catch (error) {

        }

    }

    const deletecar = async (_id) => {
        try {
            const _delete = await axios.get(`${baseApi}/api/admin/delete/cars/${_id}`, {
                headers: {
                    Authorization: localStorage.getItem('access_token')
                }
            }).then((resp) => {
                mutate();
            })
            // console.log(_delete);
        } catch (error) {

        }

    }

    const submit = async event => {
        event.preventDefault()
        try {
            const formData = new FormData();
            formData.append("image", file)
            await axios.post(`${baseApi}/api/admin/create/cars`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    mutate();
                })
        } catch (error) {

        }

    }

    const fileSelected = event => {
        const file = event.target.files[0]
        setFile(file)
        // console.log(file);
    }

    const selectFileImages = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            const newLists = [...imageList]
            newLists.push(file)
            setImageList(newLists);
        }
    }

    const fileListUpload = async (_id, cars_image) => {
        try {
            setLoadingUpload(true);
            const formData = new FormData();
            imageList.forEach(element => {
                formData.append("image", element)
            });
            formData.append("cars_image", JSON.stringify(cars_image))
            const updateData = await axios.post(`${baseApi}/api/admin/update/cars/image/gallery/${_id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    mutate();
                    setLoadingUpload(false);
                    setImageList([]);
                })
        } catch (error) {

        }

    }

    const selectDeleteFile = (image, event) => {
        // console.log(event.target.checked);
        const newLists = [...deleteImage];
        const find = newLists.find(e => e == image);
        if (find) {
            const newlist = newLists.filter(e => e != image)
            if (!event.target.checked)
                setDeleteImage(newlist);
        } else {
            newLists.push(image);
            if (event.target.checked)
                setDeleteImage(newLists);
        }

    }

    const deleteFileImageGallery = async (_id, imagelistupdate) => {
        setLoadingImage(true)
        imagelistupdate = imagelistupdate.filter((e) => {
            return deleteImage.indexOf(e) == -1
        })

        try {
            const updateData = await axios.post(`${baseApi}/api/admin/delete/cars/image/gallery/${_id}`,
                {
                    cars_image: imagelistupdate,
                    cars_image_delete: deleteImage
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    mutate();
                    setLoadingImage(false);
                    setDeleteImage([]);
                })
        } catch (error) {

        }
    }

    const testlogout = () => {
        setLoading(true)
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_position');
        setTimeout(() => window.location.reload(), 1000);
    }
    const testsession = () => {
        // setLoading(true)
        // console.log(localStorage.getItem('access_token'));
        // console.log(localStorage.getItem('refresh_token'));

        // console.log(decodedToken, isExpired);
        // setTimeout(() => setLoading(false), 1000);
    }

    const updateModel = async () => {
        const subModel = [
            "ZS-EV D (MY22)",
        "ZS-EV (MY19)",
        "ZS-EV X (MY22)"
        ];
        const _id = "64bf76847cede8001d8afb22";
        try {
            const _update = await axios.post(`${baseApi}/api/admin/update/model/${_id}`,
                { model_submodel: JSON.stringify(subModel) },
                {
                    headers: {
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    if (resp.data) {

                    }
                })
        } catch (error) {

        }
    }

    // console.log(data);

    // return (
    //     <div>
    //         <Box pt={100}>
    //             <Button onClick={testlogin}>login</Button>
    //             <Button onClick={testlogout}>log out</Button>
    //             <Button onClick={testsession}>test session</Button>
    //             <Box>
    //                 {
    //                     !loading ?
    //                         <>
    //                             {
    //                                 !!localStorage.getItem('access_token') ?
    //                                     'Login' : 'Logout'
    //                             }</> :
    //                         <Spinner />
    //                 }

    //             </Box>
    //         </Box>
    //         <Box pt={100}>
    //             <form onSubmit={submit} style={{ width: 650 }}>
    //                 <input onChange={fileSelected} type="file" accept="image/*"></input>
    //                 <Button type="submit">Submit</Button>
    //             </form>
    //             {data ?
    //                 data?.map((e, i) => {
    //                     return (
    //                         <Box>
    //                             <Stack direction='row'>
    //                                 <div key={i}>
    //                                     <Box>
    //                                         {e.cars_title}
    //                                     </Box>
    //                                     {
    //                                         e.cars_image_default ?
    //                                             <Box>
    //                                                 <Image
    //                                                     boxSize='250px'
    //                                                     objectFit='cover'
    //                                                     src={`${process.env.REACT_APP_S3_BASE_URI}/Default/${e.cars_image_default}`}
    //                                                     alt={e.cars_title} />
    //                                             </Box>
    //                                             : ''
    //                                     }
    //                                     {
    //                                         !loadingUpload ?
    //                                             <Box>
    //                                                 <input type="file" onChange={(event) => selectFileImages(event)} accept="image/*"></input>
    //                                                 <Button onClick={() => fileListUpload(e._id, e.cars_image)}>Upload</Button>
    //                                             </Box>
    //                                             :
    //                                             <>Loading...</>
    //                                     }

    //                                     <Stack direction='row'>
    //                                         {
    //                                             imageList.length ?
    //                                                 imageList.map((ele, idx) => {
    //                                                     let uri = URL.createObjectURL(ele);
    //                                                     return (
    //                                                         <Box>
    //                                                             <Image
    //                                                                 key={`${idx}.${i}.imageList`}
    //                                                                 boxSize='100px'
    //                                                                 objectFit='cover'
    //                                                                 src={uri}
    //                                                                 alt={`uploadList.${idx}`} />
    //                                                             <CloseButton onClick={() => {
    //                                                                 const imageLists = [...imageList];
    //                                                                 imageLists.splice(idx, 1);
    //                                                                 console.log(imageLists, idx);
    //                                                                 setImageList(imageLists);
    //                                                             }}>

    //                                                             </CloseButton>
    //                                                         </Box>
    //                                                     )
    //                                                 })
    //                                                 : <></>
    //                                         }
    //                                     </Stack>


    //                                 </div>
    //                                 <div>
    //                                     <Box pt={50}>

    //                                         {
    //                                             e.cars_detail ?
    //                                                 Object.entries(e.cars_detail).map(([key, value], i) => {
    //                                                     return (
    //                                                         <Box>
    //                                                             {key} : {value}
    //                                                         </Box>
    //                                                     )
    //                                                 })
    //                                                 : ''
    //                                         }
    //                                     </Box>
    //                                 </div>
    //                             </Stack>
    //                             {
    //                                 !loadingImage ?
    //                                     <>
    //                                         {
    //                                             e.cars_image ?
    //                                                 <Box pb={4} pt={4}>
    //                                                     <Stack direction='row'>{
    //                                                         e.cars_image.map((element, idx) => {
    //                                                             return (
    //                                                                 <Box>
    //                                                                     <Image
    //                                                                         boxSize='100px'
    //                                                                         objectFit='cover'
    //                                                                         src={`${process.env.REACT_APP_S3_BASE_URI}/${e._id}/${element}`}
    //                                                                         alt={element} />
    //                                                                     <Checkbox key={`${e._id}/${element}`} onChange={(event) => { selectDeleteFile(element, event) }}>Delete</Checkbox>
    //                                                                 </Box>
    //                                                             )
    //                                                         })}
    //                                                     </Stack>
    //                                                     <Button onClick={() => {
    //                                                         deleteFileImageGallery(e._id, e.cars_image)
    //                                                     }}>Delete Image</Button>
    //                                                     <Button>Cancel</Button>
    //                                                 </Box>
    //                                                 : ''
    //                                         }
    //                                     </> : <>Loading...</>
    //                             }

    //                             <Button onClick={() => {
    //                                 deletecar(e._id)
    //                             }}>DELETE</Button>
    //                         </Box>


    //                     )
    //                 }) : ''
    //             }
    //         </Box>
    //     </div>
    // )

    return (
        <div>
            <Box pt={100}>
                <Button onClick={() => { updateModel() }}>aadads</Button>
            </Box>
        </div>
    )
}

export default Test;