// Chakra imports
import {
  Box,
  Divider,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Badge,
  Icon,
  Button,
  TableContainer,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesAllcarRow from "components/Tables/TablesAllcarRow";
import Paginate from "components/Paginate/Paginate";
import React, { useState, useEffect } from "react";

import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { PiTreeStructureBold } from "react-icons/pi";
import { AiOutlineSearch } from 'react-icons/ai'

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel
} from "@tanstack/react-table";


import { FaEllipsisV } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import IconButtonDelete from "components/IconButton/IconButtonDelete";
import IconButtonEdit from "components/IconButton/IconButtonEdit";
import IconButtonMenu from "components/IconButton/IconButtonmenu";


const Allcar = ({ title, columns, data, mutate, setFilterData }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [sorting, setSorting] = useState([]);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState();

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  });
  // console.log(data[0].cars_tag);

  const formateDate = (date) => {
    let objectDate = new Date(date);
    return `${objectDate.toTimeString().split(' ')[0]} ${objectDate.getDate()}/${objectDate.getMonth() + 1}/${objectDate.getFullYear()}`;
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setFilterData(e.target.value);
  }

  return (
    <Card w={'full'} >
      <CardHeader p='6px 0px 12px 0px'>
        <Flex w={'full'} align={'center'} justify={'end'} gap={'12px'} >
          <Flex w={'full'}>
            <Text fontSize='lg' color={textColor} fontWeight='bold'   >
              {title}
            </Text>
          </Flex>

          <InputGroup w={'fit-content'} size={'sm'} flexShrink={0}>
            <InputLeftElement pointerEvents='none' >
              <AiOutlineSearch />
            </InputLeftElement>
            <Input maxW={'240px'} type={'search'} placeholder={'ค้นหา'} rounded={'6px'} value={value} onChange={(e) => handleSearch(e)} />
          </InputGroup>

          <Button
            variant={'outline'}
            colorScheme="teal"
            size={'sm'}
            flexShrink={0}
            leftIcon={<Icon as={IoMdAdd} />}
            onClick={() => {
              window.location.href = `/#/form/addcar`;
            }}
          >
            เพิ่มข้อมูลรถ
          </Button>
        </Flex>
      </CardHeader>
      <Divider />
      <CardBody overflowX={{ sm: "auto", xl: "hidden" }}>
        <TableContainer w={'full'}>
          <Table variant='simple' color={textColor} >
            <Thead>
              {
                table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id} my='.8rem' pl='0px'>
                    {headerGroup.headers.map((header) => {
                      const meta = header.column.columnDef.meta;
                      return (
                        <Th
                          color='gray.400'
                          key={header.id}
                          ps={header.id === 0 ? "0px" : null}
                          onClick={header.column.getToggleSortingHandler()}
                          isNumeric={meta?.isNumeric}
                          cursor='pointer'
                          align="center"
                        >
                          <Flex align={'center'} gap={'4px'} justify={meta?.isNumeric ? 'end' : 'start'} >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <TbTriangleFilled size={14} aria-label="sorted descending" />
                              ) : (
                                <TbTriangleInvertedFilled size={14} aria-label="sorted ascending" />
                              )
                            ) : null}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))
              }
            </Thead>
            <Tbody>
              {table.getRowModel().rows.slice(page * 10, page * 10 + 10).map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    const meta = cell.column.columnDef.meta;
                    // console.log(row.index);
                    // console.log(row.getVisibleCells());
                    return (
                      cell.column.id === 'cars_status' ?
                        <Td key={cell.id} isNumeric={meta?.isNumeric}>
                          <Badge
                            variant={
                              cell.getValue() === "SELL" ? "solid" : "outline"
                            }
                            colorScheme={
                              cell.getValue() === "SELL" ? "green" : cell.getValue() === "RESERVE" ? "yellow" : "red"
                            }
                          >
                            {
                              cell.getValue() == "SELL" ? "กำลังขาย" : cell.getValue() == "RESERVE" ? "จองแล้ว" : "ขายแล้ว"
                            }
                          </Badge>
                        </Td>
                        : cell.column.id === 'updateDate' || cell.column.id === 'createDate' ?
                          <Td key={cell.id} isNumeric={meta?.isNumeric}>
                            {
                              formateDate(cell.getValue())
                            }
                          </Td>
                          : cell.column.id === 'cars_detail_price' ?
                            <Td key={cell.id} isNumeric={meta?.isNumeric} >
                              {data[row.index].cars_detail.price} บาท
                            </Td>
                            : cell.column.id === 'cars_title' ?
                              <Td key={cell.id} isNumeric={meta?.isNumeric}>
                                {
                                  data[row.index]?.cars_tag === 'main' ?
                                    <>
                                      <Badge colorScheme={'green'} mr={'4px'}>
                                        หน้าหลัก
                                      </Badge>
                                      {cell.getValue()}
                                    </>
                                    : data[row.index]?.cars_tag === 'hot' ?
                                      <>
                                        <Badge colorScheme={'red'} mr={'4px'}>
                                          ยอดนิยม
                                        </Badge>
                                        {cell.getValue()}
                                      </>
                                      :
                                      cell.getValue()
                                }
                              </Td>
                              :
                              <Td key={cell.id} isNumeric={meta?.isNumeric}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Td>
                    );
                  })}
                  <Td isNumeric display={'flex'} flexShrink={0}>
                    <IconButtonEdit onClick={() => {
                      window.location.href = `/#/form/editcar/${row.original._id}`;
                    }} />
                    <IconButtonDelete id={row.original._id} type={'car'} mutate={() => mutate()} />
                    <IconButtonMenu
                      id={row.original._id}
                      data={data}
                      tag={data[row.index].cars_tag}
                      status={data[row.index].cars_status}
                      mutate={() => mutate()} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
            {/* <Tbody>
            {pagiData.map((row) => {
              return (
                <TablesAllcarRow
                  key={row.id}
                  carname={row.car_name}
                  car_brand={row.car_brand}
                  car_model={row.car_model}
                  price={row.price}
                  update={row.update}
                  timestamp={row.timestamp}
                />
              );
            })}
          </Tbody> */}
          </Table>
        </TableContainer>
      </CardBody>
      <Paginate data={data} page={page} setPage={setPage} />

    </Card >
  );
};

export default Allcar;
