// chakra imports
import { Box, ChakraProvider, Portal } from '@chakra-ui/react';
import Footer from 'components/Footer/Footer.js';
// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from 'theme/theme.js';
import { useJwt } from "react-jwt";

const baseApi = process.env.REACT_APP_BASE_API;

export default function Pages(props) {
	const { ...rest } = props;
	const { decodedToken, isExpired } = useJwt(localStorage.getItem('access_token'));
	// ref for the wrapper div
	const wrapper = React.createRef();
	React.useEffect(() => {
		document.body.style.overflow = 'unset';
		// Specify how to clean up after this effect:
		return function cleanup() { };
	});
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].views);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].views);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					if (routes[i].secondaryNavbar) {
						return routes[i].secondaryNavbar;
					}
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.category === 'account') {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/auth') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};
	const getLocalstorage = () => {
		if (localStorage.getItem('user_name')) {
			if (isExpired) {
				const loginUser = async () => {
					try {
						const _login = await axios.post(`${baseApi}/api/user/refresh/token`,
							{
								_id: decodedToken.user_id
							},
							{
								headers: {
									Authorization: localStorage.getItem('refresh_token')
								}
							}
						).then((resp) => {
							// console.log(resp);
							if (resp.data) {
								localStorage.setItem('access_token', resp?.data?.access_token);
								localStorage.setItem('refresh_token', resp?.data?.refresh_token);
								localStorage.setItem('user_name', resp?.data?.result?.username);
								localStorage.setItem('user_position', resp?.data?.result?.position);
								setTimeout(() => window.location.href = '/admin', 1000)
							}

						})
					} catch (error) {

					}
				}
				loginUser();
			} else {
				return true
			}
		}
		return false
	}

	const navRef = React.useRef();
	document.documentElement.dir = 'ltr';
	return (
		<ChakraProvider theme={theme} resetCss={false} w='100%'>
			<Box ref={navRef} w='100%'>
				{/* <Portal containerRef={navRef}>
					<AuthNavbar secondary={getActiveNavbar(routes)} logoText='PURITY UI DASHBOARD' />
				</Portal> */}
				<Box w='100%'>
					<Box ref={wrapper} w='100%'>
						<Switch>

							{
								getLocalstorage() ? <>(
									{getRoutes(routes)}
									< Redirect from='/auth' to='/admin' />
									)</>
									: <>
										({getRoutes(routes)}
										< Redirect from='/auth' to='/auth/signin' />)
									</>
							}
						</Switch>
					</Box>
				</Box>
				<Box px='24px' mx='auto' width='1044px' maxW='100%'>
					<Footer />
				</Box>
			</Box>
		</ChakraProvider>
	);
}
