import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { Flex, Button, FormControl, FormLabel, Input, InputGroup, Select, InputRightElement } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import axios from 'axios'

const baseApi = process.env.REACT_APP_BASE_API;


function FormAddAccount({ editdata, setData, onClose, success_toast, error_toast, editsuccess_toast, editerror_toast, mutate }) {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            position: '',
        },
        onSubmit: (values) => {
            console.log(values);
            if (!editdata) {
                try {
                    const createAccount = async () => {
                        const _create = await axios.post(`${baseApi}/api/admin/register`,
                            values,
                            {
                                headers: {
                                    Authorization: localStorage.getItem('access_token')
                                }
                            }).then((resp) => {
                                if (resp.data) {
                                    success_toast()
                                    mutate()
                                    onClose();
                                }
                            })
                    }
                    createAccount();
                } catch (error) {
                    alert(error)
                    error_toast()
                    mutate()
                }
            } else {
                try {
                    const updateAccount = async (id) => {
                        const _update = await axios.post(`${baseApi}/api/admin/update/user/${id}`,
                            { password: values.password },
                            {
                                headers: {
                                    Authorization: localStorage.getItem('access_token')
                                }
                            }).then((resp) => {
                                if (resp.data) {
                                    editsuccess_toast()
                                    mutate()
                                    onClose();
                                }
                            })
                    }
                    updateAccount(editdata._id);
                } catch (error) {
                    alert(error)
                    editerror_toast()
                    mutate()
                }
            }
        },
    })

    // console.log("editdata", editdata);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex flexDir={'column'} >
                {
                    editdata ? (
                        <FormControl mt={4} >
                            <FormLabel htmlFor='model_name' fontSize={'lg'}>รหัสผ่าน</FormLabel>
                            <InputGroup>
                                <Input
                                    id='password'
                                    name='password'
                                    type={show ? 'text' : 'password'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                />
                                <InputRightElement width='4.5rem'>
                                    {
                                        show ?
                                            <ViewIcon onClick={handleClick} /> : <ViewOffIcon onClick={handleClick} />
                                    }
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                    ) : (<>
                        <FormControl mt={4} >
                            <FormLabel htmlFor='username' fontSize={'lg'}>ชื่อผู้ใช้</FormLabel>
                            <InputGroup>
                                <Input
                                    id='username'
                                    name='username'
                                    type='text'
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl mt={4} >
                            <FormLabel htmlFor='password' fontSize={'lg'}>รหัสผ่าน</FormLabel>
                            <InputGroup>
                                <Input
                                    id='password'
                                    name='password'
                                    type={show ? 'text' : 'password'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                />
                                <InputRightElement width='4.5rem'>
                                    {
                                        show ?
                                            <ViewIcon onClick={handleClick} /> : <ViewOffIcon onClick={handleClick} />
                                    }
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl mt={4} >
                            <FormLabel htmlFor='position' fontSize={'lg'}>ตำแหน่ง</FormLabel>
                            <Select id='position' name='position' placeholder='ตำแหน่ง' _focus={{ outline: 'none' }} value={formik.values.position} onChange={formik.handleChange}>
                                <option key={0} value={'ADMIN'}>
                                    แอดมิน
                                </option>
                                <option key={1} value={'EMPLOYEE'}>
                                    พนักงาน
                                </option>
                            </Select>
                        </FormControl>
                    </>
                    )
                }
                <Flex mt={12} justifyContent={'end'} >
                    <Button
                        variant={'solid'}
                        colorScheme={'teal'}
                        minW={'120px'}
                        type='submit'
                        disabled={
                            formik.values.model_name === '' &&
                            true
                        }
                    >
                        {
                            editdata ? 'แก้ไข' : 'เพิ่ม'
                        }
                    </Button>
                </Flex>
            </Flex>
        </form>
    )
}

export default FormAddAccount