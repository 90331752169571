// Chakra imports
import { Flex, Tabs, TabList, Tab, TabPanels, TabPanel, Skeleton, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Authors from "./components/Authors";
import Allcar from "./components/allcar";
import { tablesTableData, dashboardTableData } from "variables/general";
import { cardata } from "variables/carmockup";

import { createColumnHelper } from "@tanstack/react-table";

import useSWR from 'swr'

const baseapi = process.env.REACT_APP_BASE_API

export default function Tables() {

  const fetcher = (url) => fetch(url).then((res) => res.json())
  const { data, error, mutate } = useSWR(`${baseapi}/api/store/cars`, fetcher)
  const [filterData, setFilterData] = useState('');
  // console.log(process.env.REACT_APP_BASE_API);

  if (error) return <div>failed to load</div>
  if (!data) return <Skeleton height='100%' width='100%' />
  // console.log(data.filter(x => x.cars_status === "SELL"))

  if (data) {
    var data_reserve = data.filter(item => item.cars_status === "RESERVE")
    var data_sold = data.filter(item => item.cars_status === "SOLD")
  }

  const carFilter = (carData) => {
    let filter = carData.filter((ele, idx) => {
      return (ele._id?.toUpperCase().indexOf(filterData?.toUpperCase()) > -1) || (ele.cars_title?.toUpperCase().indexOf(filterData?.toUpperCase()) > -1)
    })
    return filter;
  }



  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("_id", {
      cell: (info) => info.getValue(),
      header: "รหัสรถยนต์"
    }),
    columnHelper.accessor("cars_title", {
      cell: (info) => info.getValue(),
      header: "ชื่อ"
    }),
    columnHelper.accessor("cars_status", {
      cell: (info) => info.getValue(),
      header: "สถานะ"
    }),
    columnHelper.accessor("cars_detail.price", {
      cell: (info) => info.getValue(),
      header: "ราคา",
      meta: {
        isNumeric: true
      }
    }),
    // columnHelper.accessor("createDate", {
    //   cell: (info) => info.getValue(),
    //   header: "วันที่สร้าง",
    //   meta: {
    //     isNumeric: true
    //   }
    // }),
  ];





  return (
    <Flex w={'full'} direction='column' pt={{ base: "120px", md: "75px" }}>
      <Tabs isLazy variant='enclosed'>
        <TabList gap={'12px'}>
          <Tab _focus={{ boxShadow: "none" }} >รถทั้งหมด</Tab>
          <Tab _focus={{ boxShadow: "none" }} > จองแล้ว</Tab>
          <Tab _focus={{ boxShadow: "none" }} >ขายแล้ว</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {
              data ?
                <Allcar
                  title={"รถทั้งหมด"}
                  columns={columns}
                  data={carFilter(data)}
                  mutate={mutate}
                  setFilterData={setFilterData}
                />
                :
                <Skeleton h={'50vh'} rounded={'2xl'} />
            }
          </TabPanel>
          <TabPanel>
            {
              data_reserve ?
                <Allcar
                  title={"รถที่จองแล้ว"}
                  columns={columns}
                  data={carFilter(data_reserve)}
                  mutate={mutate}
                  setFilterData={setFilterData}
                />
                :
                <Skeleton h={'50vh'} rounded={'2xl'} />
            }
          </TabPanel>
          <TabPanel>
            {
              data_sold ?
                <Allcar
                  title={"รถที่ขายแล้ว"}
                  columns={columns}
                  data={carFilter(data_sold)}
                  mutate={mutate}
                  setFilterData={setFilterData}
                />
                :
                <Skeleton h={'50vh'} rounded={'2xl'} />
            }
          </TabPanel>
        </TabPanels>
      </Tabs>


    </Flex>
  );
}


