import { Menu, MenuButton, IconButton, MenuList, MenuItem } from '@chakra-ui/react'
import React from 'react'

import { FiMoreVertical } from 'react-icons/fi'
import MenuChangeMainCar from 'components/MenuChangeMainCar/MenuChangeMainCar'
import MenuChangeHotCar from 'components/MenuChangeHotCar/MenuChangeHotCar'
import MenuChangeStatusCar from 'components/MenuChangeStatusCar/MenuChangeStatusCar'


const IconButtonMenu = ({ id, data, tag, status , mutate }) => {
    // console.log(tag);

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                variant={'ghost'}
                aria-label='Menu'
                icon={<FiMoreVertical />}
            />
            <MenuList>
                <MenuItem alignItems={'center'}
                    onClick={() => {
                        window.location.href = `/#/detail/cardetail/${id}`
                    }}
                >
                    ดูข้อมูลเพิ่มติม
                </MenuItem>
                <MenuChangeStatusCar id={id} status={status}  mutate={() => mutate()} />
                {/* <MenuChangeMainCar id={id} data={data} mutate={() => mutate()} /> */}
                <MenuChangeHotCar id={id} tag={tag} mutate={() => mutate()} />
            </MenuList>
        </Menu>
    )
}

export default IconButtonMenu