import React from 'react'

function Stepper({ step }) {

    const steps = [
        { title: 'First', description: 'Contact Info' },
        { title: 'Second', description: 'Date & Time' },
        { title: 'Third', description: 'Select Rooms' },
    ]
    return (
        <div>
            stepper{step}
        </div>
    )
}

export default Stepper