import React, { useState } from 'react'

import Imageupload from '../../../../components/ImageUpload/Imageupload';
import { AspectRatio, Flex, FormControl, Grid, Image, Input, VisuallyHidden, Button, IconButton, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { AiFillDelete } from 'react-icons/ai'
import axios from 'axios'


const baseImage = process.env.REACT_APP_S3_BASE_URI
const baseApi = process.env.REACT_APP_BASE_API;

export default function FormEditGallery({ _id, data, mutate }) {
    // console.log(data);
    const [selectimage, setSelectimage] = useState([])

    const editsuccess = useToast({
        title: 'เพิ่มข้อมูลสำเร็จ',
        position: 'top-right',
        description: `แก้ไขข้อมูลรุ่นรถสำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })

    const editerror = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `แก้ไขข้อมูลไม่สำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })
    const imageChange = (e) => {
        const files = e.target.files
        const files_name = [...formik.values.cars_image]
        for (let i = 0; i < files.length; i++) {
            setSelectimage(prev => [...prev, files[i]])
            files_name.push(files[i].name)
        }
        formik.setFieldValue('cars_image', files_name)
    }

    const formik = useFormik({
        initialValues: {
            cars_image: []
        },
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2))
            console.log(values, selectimage);
            if (selectimage.length) {
                const fileListUpload = async (_id) => {
                    try {
                        const formData = new FormData();
                        selectimage.forEach(element => {
                            formData.append("image", element)
                        });
                        formData.append("cars_image", JSON.stringify(data))
                        const updateData = await axios.post(`${baseApi}/api/admin/update/cars/image/gallery/${_id}`,
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    Authorization: localStorage.getItem('access_token')
                                }
                            }).then((resp) => {
                                console.log(resp);
                                setSelectimage([])
                                editsuccess();
                                mutate();
                            })
                    } catch (error) {
                        console.log(error);
                        editerror()
                    }

                }
                fileListUpload(_id);
            }
        }
    })

    const deleteFileImageGallery = async (_id, imagelistupdate, deleteImage) => {
        console.log(_id, imagelistupdate);
        imagelistupdate = imagelistupdate.filter((e) => {
            return deleteImage.indexOf(e) == -1
        })

        try {
            const updateData = await axios.post(`${baseApi}/api/admin/delete/cars/image/gallery/${_id}`,
                {
                    cars_image: imagelistupdate,
                    cars_image_delete: deleteImage
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    if (resp.data)
                        mutate();
                })
        } catch (error) {
        }
    }


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormControl mt={4} >
                    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }} gap={4} >
                        {
                            data.length > 0 &&
                            data.map((item, index) => {
                                return (
                                    <AspectRatio key={index} pos={'relative'} ratio={16 / 9} bg={'whiteAlpha.100'} border={'1px solid'} borderColor={'whiteAlpha.300'} rounded={'lg'} overflow={'hidden'} mb={'12px'}>
                                        <>
                                            <Image
                                                src={`${baseImage}/${_id}/${item}`}
                                                w={'full'}
                                                fit={'contain !important'}
                                                pos={'relative'}
                                            />
                                            <IconButton
                                                variant={'solid'}
                                                colorScheme={'red'}
                                                pos={'absolute'}
                                                top={'4px !important'}
                                                left={'unset !important'}
                                                right={'4px !important'}
                                                w={'24px !important'}
                                                h={'40px !important'}
                                                aria-label={'Remove Image index'}
                                                icon={<AiFillDelete />}
                                                onClick={(e) => {
                                                    deleteFileImageGallery(_id, data, [item]);

                                                }
                                                }
                                            />
                                        </>
                                    </AspectRatio>
                                )
                            })}


                        {
                            selectimage.length > 0 &&
                            selectimage.map((item, index) => {
                                return (
                                    <AspectRatio key={index} pos={'relative'} ratio={16 / 9} bg={'whiteAlpha.100'} border={'1px solid'} borderColor={'whiteAlpha.300'} rounded={'lg'} overflow={'hidden'} mb={'12px'}>
                                        <>
                                            <Image
                                                src={URL.createObjectURL(item)}
                                                w={'full'}
                                                fit={'contain !important'}
                                                pos={'relative'}
                                            />
                                            <IconButton
                                                variant={'solid'}
                                                colorScheme={'red'}
                                                pos={'absolute'}
                                                top={'4px !important'}
                                                left={'unset !important'}
                                                right={'4px !important'}
                                                w={'24px !important'}
                                                h={'40px !important'}
                                                aria-label={'Remove Image index'}
                                                icon={<AiFillDelete />}
                                                onClick={() => {
                                                    setSelectimage(prev => prev.filter((_, i) => i !== index))
                                                    formik.setFieldValue('cars_image', formik.values.cars_image.filter((_, i) => i !== index))
                                                }}
                                            />
                                        </>
                                    </AspectRatio>
                                )
                            })
                        }
                    </Grid>
                    <Imageupload
                        target={'image'}
                    />
                    <VisuallyHidden>
                        <Input
                            id='image'
                            name='image'
                            type='file'
                            onChange={imageChange}
                            multiple={true}
                            accept='image/*'
                        />
                    </VisuallyHidden>
                </FormControl>
                <Flex mt={12} justifyContent={'end'} >
                    <Button
                        variant={'solid'}
                        size={'lg'}
                        colorScheme={'teal'}
                        minW={'120px'}
                        type='submit'
                    >
                        อัปโหลด
                    </Button>
                </Flex>
            </form>
        </>
    )
}
