import { IconButton } from '@chakra-ui/react'
import React from 'react'

import { AiFillEdit } from 'react-icons/ai'
const IconButtonEdit = ({ ...restprops }) => {
    return (
        <IconButton
            variant={'ghost'}
            colorScheme={'yellow'}
            aria-label='Edit'
            icon={<AiFillEdit />}
            onClick={restprops.onClick}
        />
    )
}

export default IconButtonEdit