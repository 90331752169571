// Chakra imports
import { Flex, Skeleton } from "@chakra-ui/react";
import React from "react";
import Account from "./components/Account";
import { tablesTableData, dashboardTableData } from "variables/general";
import { model } from "../../../variables/carmodelmockup"

import { createColumnHelper } from "@tanstack/react-table";
import useSWR from 'swr'


const baseapi = process.env.REACT_APP_BASE_API

export default function Tables() {

  // const data = model
  const fetcher = (url, token) => fetch(url, { headers: { Authorization: localStorage.getItem('access_token') } }).then((res) => res.json())
  const { data, error, mutate } = useSWR(`${baseapi}/api/admin/user/all`, fetcher)

  if (error) return <div>failed to load</div>
  if (!data) return <Skeleton height='100%' width='100%' />

  // console.log(data);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("username", {
      cell: (info) => info.getValue(),
      header: "ชื่อผู้ใช้"
    }),
    // columnHelper.accessor("password", {
    //   cell: (info) => info.getValue(),
    //   header: "รหัสผ่าน"
    // }),
    columnHelper.accessor("position", {
      cell: (info) => info.getValue(),
      header: "ตำแหน่ง"
    }),
  ];




  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Account
        title={"ผู้ใช้งาน"}
        columns={columns}
        data={data}
        mutate={mutate}
      />
    </Flex>
  );
}


