import React, { useState } from 'react'
import { Container, Flex, Heading, Center, Spinner, useToast } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'

import Stepper from './Components/Stepper'
import FormAddcar from './Components/FormAddcar'
import FormAddMore from './Components/FormAddMore'
import FormAddGallery from './Components/FormAddGallery'

export default function Index() {
    const [data, setData] = useState()
    const [step, setStep] = useState(0)
    const [carId, setCarId] = useState('')
    const [loading, setLoading] = useState(false)

    const success_toast = useToast({
        title: 'เพิ่มข้อมูลสำเร็จ',
        position: 'top-right',
        description: `เพิ่มข้อมูลรถยนต์สำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })
    const error_toast = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `เพิ่มข้อมูลไม่สำเร็จสำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })

    // console.log(data);

    return (
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }} gap={'24px'}>
            <Container w={'full'} maxW={'container.lg'} mx={'auto'}  >
                <Card pos={'relative'} >
                    {
                        loading && (
                            <Center pos={'absolute'} inset={0} w={'100%'} h={'100%'} bg={'blackAlpha.600'} backdropFilter={'auto'} backdropBlur={'4px'} zIndex={1} rounded={'lg'}>
                                <Spinner size={'xl'} />
                            </Center>
                        )
                    }
                    <CardHeader>
                        <Heading as={'h4'} fontSize={'20px'} >
                            เพิ่มข้อมูลรถ
                        </Heading>
                    </CardHeader>

                    {/* <Stepper step={step} /> */}
                    {
                        step == 0 &&
                        <FormAddcar data={data} setData={setData} setStep={setStep}  />
                    }
                    {
                        step == 1 &&
                        <FormAddMore
                            data={data}
                            setStep={setStep}
                            setCarId={setCarId}
                            setLoading={setLoading}
                            success_toast={success_toast}
                            error_toast={error_toast}
                        />
                    }
                    {
                        step == 2 &&
                        <FormAddGallery
                            carId={carId}
                            setLoading={setLoading}
                            success_toast={success_toast}
                            error_toast={error_toast}
                        />
                    }
                </Card>
            </Container>
        </Flex>
    )
}
