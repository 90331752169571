import React, { useState } from 'react'
import {
    Heading, Image, Grid, AspectRatio, Modal, Text,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure
} from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'

const baseimage = process.env.REACT_APP_S3_BASE_URI

export default function Gallery({ data, _id }) {

    const [modalimage, setModalimage] = useState()
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Card>
                <CardHeader>
                    <Heading as={'h5'} size={'md'} mb={'24px'}>
                        ข้อมูลเสริม
                    </Heading>
                </CardHeader>
                <CardBody flexDir={'column'} gap={'24px'}>
                    {data.length > 0 ? (
                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }} gap={'24px'} p={'24px'} border={'1px'} borderColor={'whiteAlpha.300'} borderRadius={'12px'} >
                            {data.map((item, index) => {
                                // console.log(item);
                                return (
                                    <AspectRatio key={index} ratio={16 / 9} w={'full'}>
                                        <Image
                                            src={`${baseimage}/${_id}/${item}`}
                                            alt={item}
                                            width={'full'}
                                            onClick={() => {
                                                onOpen()
                                                setModalimage(item)
                                            }}
                                        />
                                    </AspectRatio>
                                )
                            }
                            )}
                        </Grid>
                    ) :
                        <Text w={'full'} textAlign={'center'} fontSize={'18px'} color={'whiteAlpha.700'} p={'24px'} bg={'whiteAlpha.100'} borderRadius={'12px'} border={'1px'} borderColor={'whiteAlpha.300'} >
                            ไม่มีรูปภาพ
                        </Text>
                    }
                </CardBody>
            </Card>

            <Modal isOpen={isOpen} size={'xl'} isCentered onClose={() => {
                onClose()
                setModalimage('')
            }
            }>
                <ModalOverlay />
                <ModalContent>
                    <AspectRatio ratio={16 / 9} w={'full'}>
                        <Image
                            src={`${baseimage}/${_id}/${modalimage}`}
                            alt={modalimage}
                            width={'full'}
                            onClick={onOpen}
                        />
                    </AspectRatio>
                </ModalContent>
            </Modal>
        </>
    )
}
