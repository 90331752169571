import React, { useState } from 'react'
import { Flex, Button, FormControl, FormLabel, Input, IconButton, useToast } from '@chakra-ui/react';
import axios from 'axios'
import { Formik, Form, Field, FieldArray } from 'formik';


import { AiFillDelete } from 'react-icons/ai';

const baseApi = process.env.REACT_APP_BASE_API;

function FormEditMore({ _id, data, mutate }) {
    const [loading, setLoading] = useState(false)
    // console.log(data);
    const editsuccess = useToast({
        title: 'เพิ่มข้อมูลสำเร็จ',
        position: 'top-right',
        description: `แก้ไขข้อมูลรุ่นรถสำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })

    const editerror = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `แก้ไขข้อมูลไม่สำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })
    return (
        <Formik
            initialValues={{
                cars_subdetail: data
            }}
            onSubmit={(values) => {
                // alert(JSON.stringify(values, null, 2));
                const asArray = Object.entries(values.cars_subdetail);
                const array_cars_subdetail = []
                const filtered = asArray.map(([key, value]) => {
                    if (value.name && value.value)
                        array_cars_subdetail.push(value);
                });
                // console.log(array_cars_subdetail);
                if (data) {
                    setLoading(true);
                    const updatasubdetail = async (id) => {
                        try {
                            const formData = new FormData();
                            formData.append("cars_subdetail", JSON.stringify(array_cars_subdetail));
                            await axios.post(`${baseApi}/api/admin/update/cars/${id}`,
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        Authorization: localStorage.getItem('access_token')
                                    }
                                }).then((resp) => {
                                    setLoading(false);
                                    editsuccess();
                                    mutate();
                                })
                            // console.log(array_cars_subdetail);
                        } catch (error) {
                            setLoading(false);
                            editerror();
                        }
                    }
                    updatasubdetail(_id);
                }
            }}
            render={({ values }) => (
                <Form>
                    <FieldArray
                        name="cars_subdetail"
                        render={arrayHelpers => (
                            <Flex direction={'column'} gap={'24px'}>
                                {values.cars_subdetail && values.cars_subdetail.length > 0 && (
                                    values.cars_subdetail.map((item, index) => (
                                        <Flex key={index} align={'end'} gap={'12px'}>
                                            <FormControl>
                                                <FormLabel htmlFor={`cars_subdetail[${index}].name`}>
                                                    ชื่อ
                                                </FormLabel>
                                                <Input
                                                    as={Field}
                                                    id={`cars_subdetail[${index}].name`}
                                                    name={`cars_subdetail[${index}].name`}
                                                    value={item.name}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel htmlFor={`cars_subdetail[${index}].value`}>
                                                    ข้อมูล
                                                </FormLabel>
                                                <Input
                                                    as={Field}
                                                    id={`cars_subdetail[${index}].value`}
                                                    name={`cars_subdetail[${index}].value`}
                                                    value={item.value}
                                                />
                                            </FormControl>

                                            <IconButton
                                                type="Button"
                                                aria-label="delete"
                                                icon={<AiFillDelete />}
                                                colorScheme="red"
                                                variant={'solid'}
                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                            />
                                        </Flex>
                                    ))
                                )}
                                <Button
                                    variant={'outline'}
                                    colorScheme={'green'}
                                    size={'sm'}
                                    type="Button"
                                    onClick={() => arrayHelpers.push('')}>
                                    เพิ่มข้อมูล
                                </Button>
                                <Flex justifyContent={'end'} >
                                    <Button
                                        variant={'solid'}
                                        size={'lg'}
                                        colorScheme={'teal'}
                                        minW={'120px'}
                                        type='submit'
                                    >
                                        แก้ไขข้อมูล
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                    />
                </Form>
            )}
        />
    )
}

export default FormEditMore