// Chakra imports
import {
  Box,
  Divider,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Badge,
  Icon,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesAllcarRow from "components/Tables/TablesAllcarRow";
import Paginate from "components/Paginate/Paginate";
import React, { useState, useEffect } from "react";

import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { PiTreeStructureBold } from "react-icons/pi";
import { AiOutlineSearch } from 'react-icons/ai'


import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel
} from "@tanstack/react-table";


import { FaEllipsisV } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import IconButtonDelete from "components/IconButton/IconButtonDelete";
import IconButtonEdit from "components/IconButton/IconButtonEdit";

import AddModel from "../../../Form/AddModel"


const Model = ({ title, columns, data, mutate, setFilterData }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [sorting, setSorting] = useState([]);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState();
  // console.log(data);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  });

  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setFilterData(e.target.value);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editdata, setEditdata] = useState()

  return (
    <>
      <Card >
        <CardHeader p='6px 0px 12px 0px'>
          <Flex w={'full'} align={'center'} justify={'end'} gap={'12px'} >
            <Flex w={'full'}>
              <Text fontSize='lg' color={textColor} fontWeight='bold'   >
                {title}
              </Text>
            </Flex>

            <InputGroup w={'fit-content'} size={'sm'} flexShrink={0}>
              <InputLeftElement pointerEvents='none' >
                <AiOutlineSearch />
              </InputLeftElement>
              <Input maxW={'240px'} type={'search'} placeholder={'ค้นหา'} rounded={'6px'} onChange={(e) => handleSearch(e)} />
            </InputGroup>

            <Button
              variant={'outline'}
              colorScheme="teal"
              size={'sm'}
              flexShrink={0}
              leftIcon={<Icon as={IoMdAdd} />}
              onClick={() => {
                onOpen()
                setEditdata()
              }}
            >
              เพิ่มรุ่นรถ
            </Button>
          </Flex>
        </CardHeader>
        <Divider />
        <CardBody overflowX={{ sm: "scroll", xl: "hidden" }}>
          <Table variant='simple' color={textColor} >
            <Thead>
              {
                table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id} my='.8rem' pl='0px'>
                    {headerGroup.headers.map((header) => {
                      const meta = header.column.columnDef.meta;
                      return (
                        <Th
                          color='gray.400'
                          key={header.id}
                          ps={header.id === 0 ? "0px" : null}
                          onClick={header.column.getToggleSortingHandler()}
                          isNumeric={meta?.isNumeric}
                          cursor='pointer'
                          align="center"
                        >
                          <Flex align={'center'} gap={'4px'} justify={meta?.isNumeric ? 'end' : 'start'} >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <TbTriangleFilled size={14} aria-label="sorted descending" />
                              ) : (
                                <TbTriangleInvertedFilled size={14} aria-label="sorted ascending" />
                              )
                            ) : null}
                          </Flex>

                        </Th>
                      );
                    })}
                  </Tr>
                ))
              }
            </Thead>
            <Tbody>
              {table.getRowModel().rows.slice(page * 10, page * 10 + 10).map((row) => (
                <Tr key={row._id}>
                  {row.getVisibleCells().map((cell) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    const meta = cell.column.columnDef.meta;
                    // console.log(cell.column.id);
                    return (
                      <Td key={cell.id} isNumeric={meta?.isNumeric}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    );
                  })}
                  <Td isNumeric display={'flex'} justifyContent={'end'} flexShrink={0}>
                    <IconButton
                      variant={'ghost'}
                      colorScheme="teal"
                      size={'sm'}
                      icon={<PiTreeStructureBold />}
                      onClick={() => {
                        window.location.href = `/#/detail/submodel/${row.original._id}`
                      }}
                    />

                    <IconButtonEdit
                      id={row.original._id}
                      onClick={() => {
                        onOpen()
                        setEditdata(row.original)
                      }} />
                    <IconButtonDelete id={row.original._id} type={'model'} mutate={() => mutate()} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
        <Paginate data={data} page={page} setPage={setPage} />
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}  >
        <ModalOverlay />
        <ModalContent mx={'12px'} >
          <ModalHeader>
            เพิ่มยี่ห้อรถ
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddModel editdata={editdata} onClose={onClose} mutate={() => mutate()} />
          </ModalBody>
        </ModalContent>

      </Modal>
    </>

  );
};

export default Model;
