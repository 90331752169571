import React, { useState } from 'react'

import Imageupload from '../../../../components/ImageUpload/Imageupload';
import { AspectRatio, Flex, FormControl, Grid, Image, Input, VisuallyHidden, Button, IconButton } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { AiFillDelete } from 'react-icons/ai'
import axios from 'axios'

const baseApi = process.env.REACT_APP_BASE_API;

export default function FormAddGallery({ carId, setLoading, success_toast, error_toast }) {

    console.log(carId);

    const [selectimage, setSelectimage] = useState([])

    const imageChange = (e) => {
        const files = e.target.files
        const files_name = [...formik.values.cars_image]
        for (let i = 0; i < files.length; i++) {
            setSelectimage(prev => [...prev, files[i]])
            files_name.push(files[i].name)
        }
        formik.setFieldValue('cars_image', files_name)
    }

    const formik = useFormik({
        initialValues: {
            cars_image: []
        },
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2))
            // console.log(values, selectimage);
            setLoading(true)
            if (selectimage.length) {
                const fileListUpload = async (_id) => {
                    try {
                        const formData = new FormData();
                        selectimage.forEach(element => {
                            formData.append("image", element)
                        });
                        formData.append("cars_image", JSON.stringify([]))
                        const updateData = await axios.post(`${baseApi}/api/admin/update/cars/image/gallery/${_id}`,
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    Authorization: localStorage.getItem('access_token')
                                }
                            }).then((resp) => {
                                setLoading(false)
                                success_toast()
                                setTimeout(() => {
                                    window.location.href = `/#/admin/tables_cars`
                                }, 1500);
                            })
                    } catch (error) {
                        setLoading(false)
                        error_toast()
                    }

                }
                fileListUpload(carId);
            }
        }
    })



    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormControl mt={4} >
                    {
                        selectimage.length > 0 &&
                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }} gap={4} >
                            {selectimage.map((item, index) => {
                                return (
                                    <AspectRatio key={index} pos={'relative'} ratio={4 / 3} bg={'whiteAlpha.100'} border={'1px solid'} borderColor={'whiteAlpha.300'} rounded={'lg'} overflow={'hidden'} mb={'12px'}>
                                        <>
                                            <Image
                                                src={URL.createObjectURL(item)}
                                                w={'full'}
                                                fit={'contain !important'}
                                                pos={'relative'}
                                            />

                                            <IconButton
                                                variant={'solid'}
                                                colorScheme={'red'}
                                                pos={'absolute'}
                                                top={'4px !important'}
                                                left={'unset !important'}
                                                right={'4px !important'}
                                                w={'24px !important'}
                                                h={'40px !important'}
                                                aria-label={'Remove Image index'}
                                                icon={<AiFillDelete />}
                                                onClick={() => {
                                                    setSelectimage(prev => prev.filter((_, i) => i !== index))
                                                    formik.setFieldValue('cars_image', formik.values.cars_image.filter((_, i) => i !== index))
                                                }}
                                            />
                                        </>
                                    </AspectRatio>
                                )
                            })}
                        </Grid>
                    }
                    <Imageupload
                        target={'image'}
                    />
                    <VisuallyHidden>
                        <Input
                            id='image'
                            name='image'
                            type='file'
                            onChange={imageChange}
                            multiple={true}
                            accept='image/*'
                        />
                    </VisuallyHidden>
                </FormControl>
                <Flex mt={12} justifyContent={'end'} >
                    <Button
                        variant={'solid'}
                        size={'lg'}
                        colorScheme={'teal'}
                        minW={'120px'}
                        type='submit'
                    >
                        อัปโหลด
                    </Button>
                </Flex>
            </form>
        </>
    )
}
