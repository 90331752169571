import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { Flex, Box, Button, FormControl, FormLabel, Input, Grid, Image, AspectRatio, VisuallyHidden, InputGroup, InputRightAddon, InputRightElement, Divider } from '@chakra-ui/react';

import Imageupload from '../../../../components/ImageUpload/Imageupload';
import Select_Brand from 'components/Select/Select_Brand';
import Select_Model from 'components/Select/Select_Model';
import Select_SubModel from 'components/Select/Select_SubModel';


const baseapi = process.env.REACT_APP_BASE_API;

function FormAddcar({ data, setData, setStep, }) {
    const [selectimage, setSelectimage] = useState()
    const imageChange = (e) => {
        console.log(e.target.files[0]);
        if (e.target.files[0]) {
            setSelectimage(e.target.files[0])
            formik.setFieldValue('cars_image', e.target.files[0])
        }
    }


    const formik = useFormik({
        initialValues: {
            cars_image: data?.cars_image ?? '',
            brand_name: data?.brand_name ?? '',
            model_name: data?.model_name ?? '',
            cars_title: data?.cars_title ?? '',
            cars_detail: data?.cars_detail ?? {
                price: '',
                sub_model: '',
                year: '',
                car_type: '',
                car_sub_type: '',
                engine_type: '',
                engine_CC: '',
                seat: '',
                gear: '',
                color: '',
                mile: ''
            }
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
            setData(values)
            setStep(1)
        },
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex flexDir={'column'} >
                <FormControl mt={4} >
                    {
                        data?.cars_image || selectimage ?
                            <AspectRatio ratio={4 / 3} w={'25%'} minW={'320px'} maxW={'640px'} bg={'whiteAlpha.100'} border={'1px solid'} borderColor={'whiteAlpha.300'} rounded={'lg'} overflow={'hidden'} mb={'12px'}>
                                <Image
                                    src={URL.createObjectURL(selectimage ?? data?.cars_image)}
                                    w={'full'}
                                    fit={'contain !important'}
                                />
                            </AspectRatio>
                            :
                            <></>
                    }
                    <Imageupload
                        target={'cars_image'}
                    />
                    <VisuallyHidden>
                        <Input
                            id='cars_image'
                            name='cars_image'
                            type='file'
                            onChange={imageChange}

                        />
                    </VisuallyHidden>
                </FormControl>
                <Divider py={4} />
                <FormControl mt={4} >
                    <FormLabel htmlFor='cars_detail.price' fontSize={'lg'}>ราคา</FormLabel>
                    <InputGroup>
                        <Input
                            id='cars_detail.price'
                            name='cars_detail.price'
                            type='number'
                            w={'clamp(300px, 50%, 500px)'}
                            value={formik.values.cars_detail.price}
                            onChange={formik.handleChange}
                        />
                        <InputRightAddon children={'บาท'} />
                    </InputGroup>
                </FormControl>
                <Divider py={4} />
                <FormControl mt={4} >
                    <FormLabel htmlFor='brand_name'>ยี่ห้อ</FormLabel>
                    <Select_Brand
                        id='brand_name'
                        name='brand_name'
                        value={formik.values.brand_name}
                        onChange={formik.handleChange} />
                </FormControl>
                <Flex gap={2} mt={4}>
                    <FormControl isRequired>
                        <FormLabel htmlFor='model_name'>รุ่นรถ</FormLabel>
                        <Select_Model
                            id='model_name'
                            name='model_name'
                            type='text'
                            value={formik.values?.model_name}
                            onChange={formik.handleChange}
                            isDisabled={formik.values?.brand_name ? false : true}
                            filter={formik.values?.brand_name}
                        >
                        </Select_Model>
                    </FormControl>
                    <FormControl >
                        <FormLabel htmlFor='cars_detail.sub_model'>รุ่นย่อย</FormLabel>
                        <Select_SubModel
                            id='cars_detail.sub_model'
                            name='cars_detail.sub_model'
                            value={formik.values.cars_detail.sub_model}
                            onChange={formik.handleChange}
                            isDisabled={formik.values.model_name ? false : true}
                            filter={formik.values?.model_name}
                        />
                    </FormControl>
                </Flex>
                <FormControl mt={4} isRequired>
                    <FormLabel htmlFor='cars_detail.year'>ปีรถ</FormLabel>
                    <Input
                        id='cars_detail.year'
                        name='cars_detail.year'
                        type={'number'}
                        max={new Date().getFullYear()}
                        value={formik.values.cars_detail.year}
                        onChange={formik.handleChange}
                    />
                </FormControl>
                <Divider py={4} />
                <FormControl mt={4} isRequired>
                    <FormLabel htmlFor='cars_title'>ชื่อหัวข้อ</FormLabel>
                    <Input
                        id='cars_title'
                        name='cars_title'
                        type={'text'}
                        value={formik.values.cars_title}
                        placeholder={`${formik.values.brand_name} ${formik.values.model_name && formik.values.model_name} ${formik.values.cars_detail.sub_model && formik.values.cars_detail.sub_model} ${formik.values.cars_detail.year && 'ปี' + formik.values.cars_detail.year}`}
                        onChange={formik.handleChange}
                    />
                    {formik.values.brand_name && formik.values.model_name && formik.values.cars_detail.year &&
                        <InputRightElement top={'50%'} bottom={'4px'} right={'4px'}>
                            <Button size={'sm'} onClick={() => {
                                formik.setFieldValue('cars_title', `${formik.values.brand_name} ${formik.values.model_name} ${formik.values.cars_detail.sub_model} ${'ปี ' + formik.values.cars_detail.year}`)
                            }}>
                                Set
                            </Button>
                        </InputRightElement>
                    }
                </FormControl>
                <Divider py={4} />
                <Flex gap={2} mt={4}>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.car_type'>ประเภทรถ</FormLabel>
                        <Input
                            id='cars_detail.car_type'
                            name='cars_detail.car_type'
                            type='text'
                            value={formik.values.cars_detail.car_type}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl >
                        <FormLabel htmlFor='cars_detail.car_sub_type'>ประเภทย่อย</FormLabel>
                        <Input
                            id='cars_detail.car_sub_type'
                            name='cars_detail.car_sub_type'
                            type='text'
                            value={formik.values.cars_detail.car_sub_type}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                </Flex>
                <FormControl mt={4} isRequired>
                    <FormLabel htmlFor='cars_detail.engine_type'>ประเภทเครื่องยนต์</FormLabel>
                    <Input
                        id='cars_detail.engine_type'
                        name='cars_detail.engine_type'
                        type={'text'}
                        value={formik.values.cars_detail.engine_type}
                        onChange={formik.handleChange}
                    />
                </FormControl>
                <FormControl mt={4} isRequired>
                    <FormLabel htmlFor='cars_detail.engine_CC'>เครื่องยนต์(CC)</FormLabel>
                    <Input
                        id='cars_detail.engine_CC'
                        name='cars_detail.engine_CC'
                        type={'number'}
                        value={formik.values.cars_detail.engine_CC}
                        onChange={formik.handleChange}
                    />
                </FormControl>
                <Grid templateColumns={'repeat(2, 1fr)'} rowGap={4} columnGap={2} mt={4}>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.seat'>จำนวนที่นั่ง</FormLabel>
                        <Input
                            id='cars_detail.seat'
                            name='cars_detail.seat'
                            type='number'
                            value={formik.values.cars_detail.seat}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.gear'>เกียร์</FormLabel>
                        <Input
                            id='cars_detail.gear'
                            name='cars_detail.gear'
                            type='text'
                            value={formik.values.cars_detail.gear}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.color'>สีรถ</FormLabel>
                        <Input
                            id='cars_detail.color'
                            name='cars_detail.color'
                            type='text'
                            value={formik.values.cars_detail.color}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.mile'>เลขไมล์</FormLabel>
                        <Input
                            id='cars_detail.mile'
                            name='cars_detail.mile'
                            type='number'
                            value={formik.values.cars_detail.mile}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                </Grid>
                <Flex mt={12} justifyContent={'end'} >
                    <Button
                        variant={'solid'}
                        size={'lg'}
                        colorScheme={'teal'}
                        minW={'120px'}
                        type='submit'
                    >
                        ถัดไป
                    </Button>
                </Flex>
            </Flex>
        </form>
    )
}

export default FormAddcar