import React, { useState, useEffect } from 'react'
import Card from 'components/Card/Card'
import FormAddModel from './Components/FormAddModel'
import axios from 'axios'
import { useToast } from '@chakra-ui/react'

const baseApi = process.env.REACT_APP_BASE_API;

export default function Index({ ...restprops }) {
    const [data, setData] = useState();
    const [brand, setBrand] = useState();

    const success_toast = useToast({
        title: 'เพิ่มข้อมูลสำเร็จ',
        position: 'top-right',
        description: `เพิ่มข้อมูลรุ่นรถสำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })
    const error_toast = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `เพิ่มข้อมูลไม่สำเร็จสำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })
    const editsuccess_toast = useToast({
        title: 'เพิ่มข้อมูลสำเร็จ',
        position: 'top-right',
        description: `เพิ่มข้อมูลรุ่นรถสำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })
    const editerror_toast = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `เพิ่มข้อมูลไม่สำเร็จสำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })

    useEffect(() => {
        if (!brand) {
            const getBrand = async () => {
                try {
                    const _brand = await axios.get(`${baseApi}/api/store/brands`)
                        .then((resp) => {
                            setBrand(resp.data);
                        })
                } catch (error) {

                }

            }
            getBrand();
        }

    }, [])


    return (
        <Card px={0} >
            <FormAddModel
                setData={setData}
                brand={brand}
                editdata={restprops.editdata}
                onClose={restprops.onClose}
                success_toast={success_toast}
                error_toast={error_toast}
                editsuccess_toast={editsuccess_toast}
                editerror_toast={editerror_toast}
                mutate={() => restprops.mutate()}
            />
        </Card>
    )
}
