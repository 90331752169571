import { Select } from '@chakra-ui/react'
import React from 'react'

import useSWR from 'swr'


const baseapi = process.env.REACT_APP_BASE_API;

export default function Select_Model({ filter, ...props }) {
    const fetcher = (url) => fetch(url).then((res) => res.json())
    const { data, error } = useSWR(`${baseapi}/api/store/models`, fetcher)
    if (error) return <div>failed to load</div>
    if (!data) return <div>loading...</div>
    const model = data.filter(item => item.brand_name === filter)
    console.log(model);
    
    return (
        <Select placeholder='Choose a model' _focus={{ outline: 'none' }} {...props} >
            {model.map((model, idx) => (
                <option id={model._id} key={idx} value={model.model_name}>
                    {model.model_name}
                </option>
            ))}
        </Select>
    )
}
