import React, { useState } from 'react'
import { useFormik } from "formik";
import { Flex, Box, Button, FormControl, FormLabel, Input, Grid, Image, AspectRatio, VisuallyHidden, InputGroup, InputRightAddon, InputRightElement, Divider, Skeleton, useToast } from '@chakra-ui/react';

import Imageupload from '../../../../components/ImageUpload/Imageupload';
import Select_Brand from 'components/Select/Select_Brand';
import Select_Model from 'components/Select/Select_Model';
import axios from 'axios';
import Select_SubModel from 'components/Select/Select_SubModel';

const baseImage = process.env.REACT_APP_S3_BASE_URI

const baseApi = process.env.REACT_APP_BASE_API;

function FormEditcar({ _id, data, mutate }) {
    const [loading, setLoading] = useState(false)
    const [selectimage, setSelectimage] = useState()
    const [selectmodel, setSelectmodel] = useState()

    const editsuccess = useToast({
        title: 'เพิ่มข้อมูลสำเร็จ',
        position: 'top-right',
        description: `แก้ไขข้อมูลรุ่นรถสำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })

    const editerror = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `แก้ไขข้อมูลไม่สำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })


    const imageChange = (e) => {
        console.log(e.target.files[0]);
        if (e.target.files[0]) {
            setSelectimage(e.target.files[0])
            formik.setFieldValue('cars_image', e.target.files[0])
        }
    }



    const handleSubModel = (e) => {
        try {
            fetch(`${baseApi}/api/store/models/${e.target.options[e.target.selectedIndex].id}`)
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    setSelectmodel(data)
                })
        } catch (err) {
            console.log(err);
        }
    }

    const formik = useFormik({
        initialValues: {
            cars_image_default: data?.cars_image_default ?? '',
            brand_name: data?.brand_name ?? '',
            model_name: data?.model_name ?? '',
            cars_title: data?.cars_title ?? '',
            cars_detail: data?.cars_detail ?? {
                price: '',
                sub_model: '',
                year: '',
                car_type: '',
                car_sub_type: '',
                engine_type: '',
                engine_CC: '',
                seat: '',
                gear: '',
                color: '',
                mile: ''
            }
        },
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2))
            const updateCar = async (id) => {
                try {
                    const formData = new FormData();
                    if (selectimage) {
                        formData.append('image', selectimage)
                        formData.append('cars_image_default', data?.cars_image_default)
                    }
                    formData.append('brand_name', values.brand_name)
                    formData.append('model_name', values.model_name)
                    formData.append('cars_title', values.cars_title)
                    formData.append('cars_detail.price', values.cars_detail.price)
                    formData.append('cars_detail.sub_model', values.cars_detail.sub_model)
                    formData.append('cars_detail.year', values.cars_detail.year)
                    formData.append('cars_detail.car_type', values.cars_detail.car_type)
                    formData.append('cars_detail.car_sub_type', values.cars_detail.car_sub_type)
                    formData.append('cars_detail.engine_type', values.cars_detail.engine_type)
                    formData.append('cars_detail.engine_CC', values.cars_detail.engine_CC)
                    formData.append('cars_detail.seat', values.cars_detail.seat)
                    formData.append('cars_detail.gear', values.cars_detail.gear)
                    formData.append('cars_detail.color', values.cars_detail.color)
                    formData.append('cars_detail.mile', values.cars_detail.mile)
                    await axios.post(`${baseApi}/api/admin/update/cars/${id}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: localStorage.getItem('access_token')
                            }
                        }).then((resp) => {
                            editsuccess()
                            mutate()
                            setLoading(false);
                        })
                } catch (error) {
                    editerror()
                    setLoading(false);
                }
            }
            updateCar(_id)
        },
    })

    if (!data) {
        return (
            <Skeleton w={'full'} h={'120px'} />
        )
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex flexDir={'column'} >
                <FormControl mt={4} >
                    {
                        !selectimage && data.cars_image &&
                        <AspectRatio ratio={4 / 3} w={'25%'} minW={'320px'} maxW={'640px'} bg={'whiteAlpha.100'} border={'1px solid'} borderColor={'whiteAlpha.300'} rounded={'lg'} overflow={'hidden'} mb={'12px'}>
                            <Image
                                src={`${baseImage}/Default/${data.cars_image_default}`}
                                w={'full'}
                                fit={'contain !important'}
                            />
                        </AspectRatio>
                    }
                    {
                        selectimage &&
                        <AspectRatio ratio={4 / 3} w={'25%'} minW={'320px'} maxW={'640px'} bg={'whiteAlpha.100'} border={'1px solid'} borderColor={'whiteAlpha.300'} rounded={'lg'} overflow={'hidden'} mb={'12px'}>
                            <Image
                                src={URL.createObjectURL(selectimage)}
                                w={'full'}
                                fit={'contain !important'}
                            />
                        </AspectRatio>
                    }
                    <Imageupload
                        target={'cars_image'}
                    />
                    <VisuallyHidden>
                        <Input
                            id='cars_image'
                            name='cars_image'
                            type='file'
                            onChange={imageChange}

                        />
                    </VisuallyHidden>
                </FormControl>
                <Divider py={4} />
                <FormControl mt={4} >
                    <FormLabel htmlFor='cars_detail.price' fontSize={'lg'}>ราคา</FormLabel>
                    <InputGroup>
                        <Input
                            id='cars_detail.price'
                            name='cars_detail.price'
                            type='number'
                            w={'clamp(300px, 50%, 500px)'}
                            value={formik.values.cars_detail.price}
                            onChange={formik.handleChange}
                        />
                        <InputRightAddon children={'บาท'} />
                    </InputGroup>
                </FormControl>
                <Divider py={4} />
                <FormControl mt={4} >
                    <FormLabel htmlFor='brand_name'>ยี่ห้อ</FormLabel>
                    <Select_Brand
                        id='brand_name'
                        name='brand_name'
                        value={formik.values.brand_name}
                        onChange={formik.handleChange} />
                </FormControl>
                <Flex gap={2} mt={4}>
                    <FormControl isRequired>
                        <FormLabel htmlFor='model_name'>รุ่นรถ</FormLabel>
                        <Select_Model
                            id='model_name'
                            name='model_name'
                            type='text'
                            value={formik.values.model_name}
                            // onChange={formik.handleChange}
                            onChange={formik.handleChange}
                            isDisabled={formik.values.brand_name ? false : true}
                            filter={formik.values.brand_name}
                        >
                        </Select_Model>
                    </FormControl>
                    <FormControl >
                        <FormLabel htmlFor='cars_detail.sub_model'>รุ่นย่อย</FormLabel>
                        <Select_SubModel
                            id='cars_detail.sub_model'
                            name='cars_detail.sub_model'
                            value={formik.values.cars_detail.sub_model}
                            onChange={formik.handleChange}
                            isDisabled={formik.values.model_name ? false : true}
                            filter={formik.values?.model_name}
                        />
                    </FormControl>
                </Flex>
                <FormControl mt={4} isRequired>
                    <FormLabel htmlFor='cars_detail.year'>ปีรถ</FormLabel>
                    <Input
                        id='cars_detail.year'
                        name='cars_detail.year'
                        type={'number'}
                        max={new Date().getFullYear()}
                        value={formik.values.cars_detail.year}
                        onChange={formik.handleChange}
                    />
                </FormControl>
                <Divider py={4} />
                <FormControl mt={4} isRequired>
                    <FormLabel htmlFor='cars_title'>ชื่อหัวข้อ</FormLabel>
                    <Input
                        id='cars_title'
                        name='cars_title'
                        type={'text'}
                        value={formik.values.cars_title}
                        placeholder={`${formik.values.brand_name} ${formik.values.model_name && formik.values.model_name} ${formik.values.cars_detail.sub_model && formik.values.cars_detail.sub_model} ${formik.values.cars_detail.year && 'ปี' + formik.values.cars_detail.year}`}
                        onChange={formik.handleChange}
                    />
                    {formik.values.brand_name && formik.values.model_name && formik.values.cars_detail.year &&
                        <InputRightElement top={'50%'} bottom={'4px'} right={'4px'}>
                            <Button size={'sm'} onClick={() => {
                                formik.setFieldValue('cars_title', `${formik.values.brand_name} ${formik.values.model_name} ${formik.values.cars_detail.sub_model} ${'ปี ' + formik.values.cars_detail.year}`)
                            }}>
                                Set
                            </Button>
                        </InputRightElement>
                    }
                </FormControl>
                <Divider py={4} />
                <Flex gap={2} mt={4}>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.car_type'>ประเภทรถ</FormLabel>
                        <Input
                            id='cars_detail.car_type'
                            name='cars_detail.car_type'
                            type='text'
                            value={formik.values.cars_detail.car_type}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl >
                        <FormLabel htmlFor='cars_detail.car_sub_type'>ประเภทย่อย</FormLabel>
                        <Input
                            id='cars_detail.car_sub_type'
                            name='cars_detail.car_sub_type'
                            type='text'
                            value={formik.values.cars_detail.car_sub_type}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                </Flex>
                <FormControl mt={4} isRequired>
                    <FormLabel htmlFor='cars_detail.engine_type'>ประเภทเครื่องยนต์</FormLabel>
                    <Input
                        id='cars_detail.engine_type'
                        name='cars_detail.engine_type'
                        type={'text'}
                        value={formik.values.cars_detail.engine_type}
                        onChange={formik.handleChange}
                    />
                </FormControl>
                <FormControl mt={4} isRequired>
                    <FormLabel htmlFor='cars_detail.engine_CC'>เครื่องยนต์(CC)</FormLabel>
                    <Input
                        id='cars_detail.engine_CC'
                        name='cars_detail.engine_CC'
                        type={'number'}
                        value={formik.values.cars_detail.engine_CC}
                        onChange={formik.handleChange}
                    />
                </FormControl>
                <Grid templateColumns={'repeat(2, 1fr)'} rowGap={4} columnGap={2} mt={4}>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.seat'>จำนวนที่นั่ง</FormLabel>
                        <Input
                            id='cars_detail.seat'
                            name='cars_detail.seat'
                            type='number'
                            value={formik.values.cars_detail.seat}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.gear'>เกียร์</FormLabel>
                        <Input
                            id='cars_detail.gear'
                            name='cars_detail.gear'
                            type='text'
                            value={formik.values.cars_detail.gear}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.color'>สีรถ</FormLabel>
                        <Input
                            id='cars_detail.color'
                            name='cars_detail.color'
                            type='text'
                            value={formik.values.cars_detail.color}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='cars_detail.mile'>เลขไมล์</FormLabel>
                        <Input
                            id='cars_detail.mile'
                            name='cars_detail.mile'
                            type='number'
                            value={formik.values.cars_detail.mile}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                </Grid>
                <Flex mt={12} justifyContent={'end'} >
                    <Button
                        variant={'solid'}
                        size={'lg'}
                        colorScheme={'teal'}
                        minW={'120px'}
                        type='submit'
                    >
                        แก้ไขข้อมูล
                    </Button>
                </Flex>
            </Flex>
        </form>
    )
}

export default FormEditcar