// Chakra imports
import { Flex, Skeleton } from "@chakra-ui/react";
import React from "react";
import Brand from "./components/Brand";
import { tablesTableData, dashboardTableData } from "variables/general";
import { brand } from "../../../variables/carbrandmockup"

import { createColumnHelper } from "@tanstack/react-table";
import useSWR from 'swr'

const baseapi = process.env.REACT_APP_BASE_API

export default function Tables() {
  // const data = brand
  const fetcher = (url) => fetch(url).then((res) => res.json())
  const { data, error, mutate } = useSWR(`${baseapi}/api/store/brands`, fetcher)

  // console.log(process.env.REACT_APP_BASE_API);

  if (error) return <div>failed to load</div>
  if (!data) return <Skeleton height='100%' width='100%' />

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("brand_image", {
      cell: (info) => info.getValue(),
      header: "รูปภาพ"
    }),
    columnHelper.accessor("brand_name", {
      cell: (info) => info.getValue(),
      header: "ยี่ห้อรถ"
    }),
  ];


  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Brand
        title={"ยี่ห้อรถ"}
        columns={columns}
        data={data}
        mutate={mutate}
      />

    </Flex>
  );
}


