// Chakra imports
import {
  Box,
  Divider,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Badge,
  Icon,
  Button,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesAllcarRow from "components/Tables/TablesAllcarRow";
import Paginate from "components/Paginate/Paginate";
import React, { useState, useEffect } from "react";

import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel
} from "@tanstack/react-table";


import { FaEllipsisV } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import IconButtonDelete from "components/IconButton/IconButtonDelete";
import IconButtonEdit from "components/IconButton/IconButtonEdit";

import AddAccount from "../../../Form/AddAccount"


const Model = ({ title, columns, data, mutate }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [sorting, setSorting] = useState([]);
  const [titleModal, setTitleModal] = useState('เพิ่มผู้ใช้งาน');
  const [page, setPage] = useState(0)
  // console.log(data);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editdata, setEditdata] = useState()

  return (
    <>
      <Card >
        <CardHeader p='6px 0px 12px 0px'>
          <Flex w={'full'} align={'center'} justify={'space-between'} >
            <Text fontSize='lg' color={textColor} fontWeight='bold' >
              {title}
            </Text>
            <Button
              variant={'outline'}
              colorScheme="teal"
              size={'sm'}
              leftIcon={<Icon as={IoMdAdd} />}
              onClick={() => {
                setTitleModal('เพิ่มผู้ใช้งาน')
                onOpen()
                setEditdata()
              }}
            >
              เพิ่มผู้ใช้งาน
            </Button>
          </Flex>
        </CardHeader>
        <Divider />
        <CardBody overflowX={{ sm: "scroll", xl: "hidden" }}>
          <Table variant='simple' color={textColor} >
            <Thead>
              {
                table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id} my='.8rem' pl='0px'>
                    {headerGroup.headers.map((header) => {
                      const meta = header.column.columnDef.meta;
                      return (
                        <Th
                          color='gray.400'
                          key={header.id}
                          ps={header.id === 0 ? "0px" : null}
                          onClick={header.column.getToggleSortingHandler()}
                          isNumeric={meta?.isNumeric}
                          cursor='pointer'
                          align="center"
                        >
                          <Flex align={'center'} gap={'4px'} justify={meta?.isNumeric ? 'end' : 'start'} >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <TbTriangleFilled size={14} aria-label="sorted descending" />
                              ) : (
                                <TbTriangleInvertedFilled size={14} aria-label="sorted ascending" />
                              )
                            ) : null}
                          </Flex>

                        </Th>
                      );
                    })}
                  </Tr>
                ))
              }
            </Thead>
            <Tbody>
              {table.getRowModel().rows.slice(page * 10, page * 10 + 10).map((row) => (
                <Tr key={row._id}>
                  {row.getVisibleCells().map((cell) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    const meta = cell.column.columnDef.meta;
                    // console.log(data[row.index]?.position);
                    return (
                      <Td key={cell.id} isNumeric={meta?.isNumeric}>
                        {
                          cell.column.id == "position" ?
                            <>{
                              data[row.index]?.position == "ADMIN" ?
                              "แอดมิน" : data[row.index]?.position == "EMPLOYEE" ?
                              "พนักงาน" : "อื่น ๆ"
                            }</>
                            :
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                        }

                      </Td>
                    );
                  })}
                  <Td isNumeric display={'flex'} justifyContent={'end'} flexShrink={0}>
                    {row.original.username !== localStorage.getItem('user_name') && row.original.position == "ADMIN" ?
                      <></>
                      :
                      <>
                        <IconButtonEdit
                          id={row.original._id}
                          onClick={() => {
                            setTitleModal('เปลี่ยนรหัสผ่าน')
                            onOpen()
                            setEditdata(row.original)
                          }} />
                        <IconButtonDelete id={row.original._id} type={'account'} mutate={() => mutate()} />
                      </>
                    }
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
        <Paginate data={data} page={page} setPage={setPage} />
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}  >
        <ModalOverlay />
        <ModalContent mx={'12px'} >
          <ModalHeader>
            {titleModal}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddAccount editdata={editdata} onClose={onClose} mutate={() => mutate()} />
          </ModalBody>
        </ModalContent>

      </Modal>
    </>

  );
};

export default Model;
