// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables_Cars from "views/Dashboard/Tables_cars";
import Tables_Brand from "views/Dashboard/Tables_Brand";
import Tables_Model from "views/Dashboard/Tables_Model";
import CarDetail from "views/Detail/CarDetail/[_id]";
import SubModel from "views/Detail/SubModel/[id]";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import Test from "views/Dashboard/Test";
import Tables_Account from "views/Dashboard/Tables_Account";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import AddCar from "views/Form/AddCar";
import Editcar from "views/Form/EditCar/[_id]"

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

import { FaCar } from "react-icons/fa";
import { TbBrandToyota } from "react-icons/tb"
import { SiMonster } from "react-icons/si"

var dashRoutes = [
  {
    path: "/dashboard",
    name: "หน้าหลัก",
    rtlName: "หน้าหลัก",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/tables_cars",
    name: "รถยนต์",
    rtlName: "Tables_cars",
    icon: <FaCar color="inherit" />,
    component: Tables_Cars,
    layout: "/admin",
  },
  {
    path: "/tables_brand",
    name: "ยี่ห้อรถ",
    rtlName: "Tables_Brand",
    icon: <TbBrandToyota color="inherit" />,
    component: Tables_Brand,
    layout: "/admin",
  },
  {
    path: "/tables_model",
    name: "รุ่นรถ",
    rtlName: "Tables_Model",
    icon: <SiMonster color="inherit" />,
    component: Tables_Model,
    layout: "/admin",
  },
  {
    path: "/submodel/:_id",
    name: "Submodel",
    rtlName: "Submodel",
    icon: <DocumentIcon color="inherit" />,
    component: SubModel,
    layout: "/detail",
  },
  {
    path: "/signin",
    name: "Sign In",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
  },
  {
    path: "/addcar",
    name: "addcar",
    rtlName: "addcar",
    icon: <DocumentIcon color="inherit" />,
    component: AddCar,
    layout: "/form",
  },
  {
    path: "/editcar/:_id",
    name: "editcar",
    rtlName: "editcar",
    icon: <DocumentIcon color="inherit" />,
    component: Editcar,
    layout: "/form",
  },
  {
    path: `/cardetail/:_id`,
    name: "cardetail",
    rtlName: "cardetail",
    icon: <DocumentIcon color="inherit" />,
    component: CarDetail,
    layout: "/detail",
  },
  // {
  //   path: "/test",
  //   name: "test",
  //   rtlName: "Test",
  //   icon: <DocumentIcon color="inherit" />,
  //   component: Test,
  //   layout: "/admin",
  // }
];

if (localStorage.getItem('user_position') === 'ADMIN') {
  dashRoutes.push({
    path: "/tables_account",
    name: "ผู้ใช้งาน",
    rtlName: "Tables_Account",
    icon: <DocumentIcon color="inherit" />,
    component: Tables_Account,
    layout: "/admin",
  })
}


export default dashRoutes;
