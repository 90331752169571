import React from 'react'
import { Container, Flex, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, useToast } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'

import FormEditcar from './Components/FormEditcar'
import FormEditMore from './Components/FormEditMore'
import FormEditGallery from './Components/FormEditGallery'

import { useParams } from 'react-router-dom'

import useSWR from 'swr'

const baseApi = process.env.REACT_APP_BASE_API

export default function Editcar() {
    const { _id } = useParams()
    const fetcher = (url) => fetch(url).then((res) => res.json())
    const { data, error, mutate } = useSWR(`${baseApi}/api/store/cars/${_id}`, fetcher)
    if (error) return <div>failed to load</div>
    if (!data) return <div>loading...</div>
    // console.log(data);

    return (
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }} gap={'24px'}>
            <Container w={'full'} maxW={'container.lg'} mx={'auto'}  >
                <Tabs isLazy variant='enclosed'>
                    <TabList gap={'12px'}>
                        <Tab _focus={{ boxShadow: "none" }} >ข้อมูลจำเพาะ</Tab>
                        <Tab _focus={{ boxShadow: "none" }} >ข้อมูลเสริม</Tab>
                        <Tab _focus={{ boxShadow: "none" }} >รูปภาพ</Tab>
                        <Tab _focus={{ boxShadow: "none" }} >ความคิดเห็นผู้ขาย</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <FormEditcar
                                _id={_id}
                                data={data}
                                mutate={() => mutate()}
                            />
                        </TabPanel>
                        <TabPanel>
                            <FormEditMore
                                _id={_id}
                                data={data.cars_subdetail}
                                mutate={() => mutate()}
                            />
                        </TabPanel>
                        <TabPanel>
                            <FormEditGallery
                                _id={_id}
                                data={data.cars_image}
                                mutate={() => mutate()}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </Flex>
    )
}
