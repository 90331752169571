import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { Flex, Box, Button, FormControl, FormLabel, Input, Grid, Image, AspectRatio, VisuallyHidden, InputGroup, InputRightAddon } from '@chakra-ui/react';

import Imageupload from 'components/ImageUpload/Imageupload';
import axios from 'axios';


const baseImage = process.env.REACT_APP_S3_BASE_URI
const baseApi = process.env.REACT_APP_BASE_API;
const Base_image_api = process.env.REACT_APP_S3_BASE_URI;

function FormAddBrand({ editdata, setData, onClose, success_toast, error_toast, editsuccess_toast, editerror_toast, mutate }) {
    const [selectimage, setSelectimage] = useState()
    const [loading, setLoading] = useState(false)

    console.log(editdata);
    const imageChange = (e) => {
        // console.log(e.target.files[0]);
        if (e.target.files[0]) {
            setSelectimage(e.target.files[0])
            formik.setFieldValue('brand_image', e.target.files[0])
        }
    }

    const formik = useFormik({
        initialValues: {
            brand_image: editdata?.brand_image ? editdata.brand_image : '',
            brand_name: editdata?.brand_name ? editdata.brand_name : '',
        },
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2))
            // console.log(values);
            if (editdata) {
                const updateBrand = async (id) => {
                    try {
                        const formData = new FormData();
                        formData.append('image', values.brand_image);
                        formData.append('brand_name', values.brand_name);
                        await axios.post(`${baseApi}/api/admin/update/brand/${id}`,
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    Authorization: localStorage.getItem('access_token')
                                }
                            }).then((resp) => {
                                // console.log('resp.data', resp.data);;
                                setLoading(false);
                                editsuccess_toast()
                                mutate();
                                onClose();
                            })
                    } catch (error) {
                        // console.log(error);
                        setLoading(false);
                        editerror_toast()
                        onClose();

                    }
                }
                updateBrand(editdata._id)

            } else {
                const createBrand = async () => {
                    try {
                        const formData = new FormData();
                        formData.append('image', values.brand_image);
                        formData.append('brand_name', values.brand_name);
                        await axios.post(`${baseApi}/api/admin/create/brand`,
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    Authorization: localStorage.getItem('access_token')
                                }
                            }).then((resp) => {
                                // console.log('resp.data', resp.data);;
                                setLoading(false);
                                success_toast()
                                mutate();
                                onClose();
                            })
                    } catch (error) {
                        // console.log(error);
                        setLoading(false);
                        error_toast()
                        onClose();
                    }
                }
                createBrand()
            }

        },
    })
    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex flexDir={'column'} >
                <FormControl mt={4} >
                    {
                        !selectimage && editdata?.brand_image &&
                        <AspectRatio ratio={1} w={'25%'} W={'240px'} bg={'whiteAlpha.100'} border={'1px solid'} borderColor={'whiteAlpha.300'} rounded={'lg'} overflow={'hidden'} mb={'12px'}>
                            <Image
                                src={`${baseImage}/Brand/${editdata.brand_image}`}
                                w={'full'}
                                fit={'contain !important'}
                            // fallbackSrc="https://via.placeholder.com/200"
                            />
                        </AspectRatio>
                    }
                    {
                        selectimage &&
                        <AspectRatio ratio={1} w={'25%'} W={'240px'} bg={'whiteAlpha.100'} border={'1px solid'} borderColor={'whiteAlpha.300'} rounded={'lg'} overflow={'hidden'} mb={'12px'}>
                            <Image
                                src={URL.createObjectURL(selectimage)}
                                w={'full'}
                                fit={'contain !important'}
                                fallbackSrc="https://via.placeholder.com/200"
                            />
                        </AspectRatio>
                    }
                    <Imageupload target={'brand_image'} />
                    <VisuallyHidden>
                        <Input
                            id='brand_image'
                            name='brand_image'
                            type='file'
                            // value={formik.values.image}
                            onChange={imageChange}
                            accept="image/*"
                        />
                    </VisuallyHidden>
                </FormControl>
                <FormControl mt={4} >
                    {/* {editdata.image} */}
                    <FormLabel htmlFor='brand_name' fontSize={'lg'}>ชื่อยี่ห้อ</FormLabel>
                    <InputGroup>
                        <Input
                            id='brand_name'
                            name='brand_name'
                            type='text'
                            value={formik.values.brand_name}
                            onChange={formik.handleChange}
                        />
                    </InputGroup>
                </FormControl>
                <Flex mt={12} justifyContent={'end'} >
                    <Button
                        variant={'solid'}
                        colorScheme={'teal'}
                        minW={'120px'}
                        type='submit'
                        disabled={
                            formik.values.brand_name === '' &&
                            true
                        }
                    >
                        บันทึก
                    </Button>
                </Flex>
            </Flex>
        </form>
    )
}

export default FormAddBrand