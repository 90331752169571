import React, { useState, useEffect } from 'react'

import { Flex } from '@chakra-ui/react'
import { Paginate } from 'react-paginate-chakra-ui'

const paginate = ({ data, page, setPage }) => {


    const handlePageClick = (p) => {
        setPage(p)
    };

    return (
        <Flex justify={'end'}>
            <Paginate
                page={page}
                count={data.length}
                pageSize={10}
                onPageChange={handlePageClick}
                // optional props 👇
                margin={1}
                shadow="lg"
                fontWeight="blue"
                variant="outline"
                // ...border and other props also work 💪
                border="2px solid"
            />
        </Flex>
    )
}

export default paginate