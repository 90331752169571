import React, { useRef, useState } from 'react'
import useSWR from 'swr'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Card from 'components/Card/Card'
import { Divider, Flex, Heading, FormControl, Input, Button, IconButton, VisuallyHidden, useToast } from '@chakra-ui/react'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import { AiFillDelete } from 'react-icons/ai'
import { BsChevronDoubleDown } from 'react-icons/bs'


import { Formik, Form, Field, FieldArray } from 'formik';


const baseApi = process.env.REACT_APP_BASE_API;

export default function SubModel() {
  const [loading, setLoading] = useState(false)
  const jumpdown = useRef(null);
  const { _id } = useParams()
  const fetcher = (url) => fetch(url).then((res) => res.json())
  const { data, error, mutate } = useSWR(`${process.env.REACT_APP_BASE_API}/api/store/models/${_id}`, fetcher)
  console.log(data?.model_submodel)

  // jumpdown 
  const jump = () => {
    jumpdown.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  const editsuccess = useToast({
    title: 'เพิ่มข้อมูลสำเร็จ',
    position: 'top-right',
    description: `แก้ไขข้อมูลรุ่นรถสำเร็จ`,
    status: 'success',
    duration: 3000,
    isClosable: true,
    containerStyle: {
      marginTop: '20px'
    }
  })

  const editerror = useToast({
    title: 'เกิดข้อผิดพลาด',
    position: 'top-right',
    description: `แก้ไขข้อมูลไม่สำเร็จ`,
    status: 'error',
    duration: 5000,
    isClosable: true,
    containerStyle: {
      marginTop: '20px'
    }
  })


  if (error) return <div>failed to load</div>
  if (!data) return <div>loading...</div>
  return (
    <>
      <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
        <Card>
          <CardHeader>
            <Flex flexDir={'column'} pb={'24px'} gap={'12px'}>
              <Heading as={'h2'}>
                Model : {data.model_name}
              </Heading>
              <Heading as={'h4'} fontSize={'lg'}>
                Brand : {data.brand_name}
              </Heading>
            </Flex>
          </CardHeader>
          <Divider />
          <CardBody p={'12px'} >
            <Formik
              initialValues={{
                model_submodel: data?.model_submodel
              }}
              onSubmit={(values) => {
                const asArray = Object.entries(values.model_submodel);
                const array_model_submodel = []
                const filtered = asArray.map(([key, value]) => {
                  if (value)
                    array_model_submodel.push(value);
                });
                if (data) {
                  setLoading(true);
                  const updatesubmodel = async (id) => {
                    try {
                      // const formData = new FormData();
                      // console.log(array_model_submodel);
                      // formData.append("model_submodel", JSON.stringify(array_model_submodel));
                      await axios.post(`${baseApi}/api/admin/update/model/${id}`,
                        {
                          model_submodel: JSON.stringify(array_model_submodel)
                        },
                        {
                          headers: {
                            // 'Content-Type': 'multipart/form-data',
                            Authorization: localStorage.getItem('access_token')
                          }
                        }).then((resp) => {
                          setLoading(false);
                          editsuccess();
                          mutate();
                        })
                      // console.log(array_cars_subdetail);
                    } catch (error) {
                      console.log(error);
                      setLoading(false);
                      editerror();
                    }
                  }
                  updatesubmodel(_id);
                }
              }}
              render={({ values }) => (
                <Form style={{
                  width: '100%',
                }}>
                  <FieldArray
                    name="model_submodel"
                    render={arrayHelpers => (
                      <Flex w={'full'} flexDir={'column'} p={'24px'} bg={'gray.800'} gap={'12px'} rounded={'16px'}>
                        {/* jump Button */}
                        <Flex w={'full'} justify={'end'} gap={'12px'}>
                          <Button
                            variant={'solid'}
                            colorScheme={'teal'}
                            size={'md'}
                            minW={'120px'}
                            type="Button"
                            onClick={() => jump()}
                            leftIcon={<BsChevronDoubleDown />}
                          >
                            ไปด้านล่างเพื่อเพิ่มข้อมูล
                          </Button>
                        </Flex>

                        {values.model_submodel && values.model_submodel.length > 0 && (
                          values.model_submodel.map((item, index) => {
                            // console.log(item);
                            return (
                              <Flex key={index} gap={'12px'}>
                                <FormControl key={index} >
                                  {/* <FormLabel htmlFor={`cmodel_submodel[${index}].name`}>
                                    ชื่อ
                                  </FormLabel> */}
                                  <Input
                                    as={Field}
                                    id={`model_submodel[${index}]`}
                                    name={`model_submodel[${index}]`}
                                    value={item}
                                  />
                                </FormControl>
                                <IconButton
                                  type="Button"
                                  aria-label="delete"
                                  icon={<AiFillDelete />}
                                  colorScheme="red"
                                  variant={'solid'}
                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                />
                              </Flex>
                            )
                          })
                        )
                        }
                        <Flex w={'full'} justify={'end'} gap={'12px'}>
                          <Button
                            variant={'outline'}
                            colorScheme={'green'}
                            size={'md'}
                            type="Button"
                            onClick={() => arrayHelpers.push('')}>
                            เพิ่มข้อมูล
                          </Button>
                          <Button
                            variant={'solid'}
                            size={'md'}
                            colorScheme={'teal'}
                            minW={'120px'}
                            type='submit'
                          >
                            บันทึก
                          </Button>
                          <VisuallyHidden ref={jumpdown} />
                        </Flex>
                      </Flex>


                    )} />
                </Form>
              )}
            />
          </CardBody>

        </Card>
      </Flex>
    </>
  )
}
