import React from 'react'
import { Flex, Box, Heading, Image, Text } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'

export default function Moredetail({ data, _id }) {
    // console.log(data);
    return (
        <>
            <Card>
                <CardHeader>
                    <Heading as={'h5'} size={'md'} mb={'24px'}>
                        ข้อมูลเสริม
                    </Heading>
                </CardHeader>
                <CardBody flexDir={'column'} gap={'24px'}>
                    <Flex flexDir={'column'} border={'1px'} borderColor={'whiteAlpha.300'} borderRadius={'12px'} overflow={'hidden'}>
                        {data.map((item, index) => {
                            return (
                                <Flex key={index}
                                    py={'12px'}
                                    px={'24px'}
                                    bg={index % 2 == 0 ? 'transparent' : 'whiteAlpha.100'}
                                >
                                    <Text  w={'150px'} flexShrink={0} >
                                        {item.name}
                                    </Text>
                                    <Text w={'full'}>
                                        {item.value ? item.value : '-'}
                                    </Text>
                                </Flex>
                            )
                        }
                        )}
                    </Flex>
                </CardBody>
            </Card>
        </>
    )
}
