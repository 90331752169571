import {
    IconButton,
    useDisclosure,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    toast,
} from '@chakra-ui/react'
import React, { useRef, useState, useEffect } from 'react'
import { AiFillDelete } from 'react-icons/ai'

import axios from 'axios'

const baseApi = process.env.REACT_APP_BASE_API;



const IconButtonDelete = ({ id, type, mutate }) => {
    // const SuccessToast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

    const deletadata = async (_id, type) => {
        if (type === 'car') {
            try {
                const _delete = await axios.get(`${baseApi}/api/admin/delete/cars/${_id}`, {
                    headers: {
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    success_toast();
                    mutate()
                })
            } catch (error) {
                error_toast()
                mutate()
            }
        } else if (type === 'brand') {
            try {
                const _delete = await axios.get(`${baseApi}/api/admin/delete/brand/${_id}`, {
                    headers: {
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    success_toast();
                    mutate()
                })
            } catch (error) {
                error_toast()
                mutate()
            }
        } else if (type === 'model') {
            try {
                const _delete = await axios.get(`${baseApi}/api/admin/delete/model/${_id}`, {
                    headers: {
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    success_toast();
                    mutate()
                })
            } catch (error) {
                error_toast()
                mutate()
            }
        } else if (type === 'account') {
            try {
                const _delete = await axios.get(`${baseApi}/api/admin//delete/user/${_id}`, {
                    headers: {
                        Authorization: localStorage.getItem('access_token')
                    }
                }).then((resp) => {
                    // console.log(resp);
                    if (resp.data._id == _id) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('user_name');
                        localStorage.removeItem('user_position');
                    }
                    success_toast();
                    mutate()
                })
            } catch (error) {
                error_toast()
                mutate()
            }
        }

    }

    const success_toast = useToast({
        title: 'ลบข้อมูลสำเร็จ',
        position: 'top-right',
        description: `${id} ลบข้อมูลรถยนต์สำเร็จ`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })
    const error_toast = useToast({
        title: 'เกิดข้อผิดพลาด',
        position: 'top-right',
        description: `ลบข้อมูลไม่สำเร็จสำเร็จ`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
            marginTop: '20px'
        }
    })




    return (
        <>
            <IconButton
                variant={'ghost'}
                colorScheme={'red'}
                aria-label='Delete'
                icon={<AiFillDelete />}
                onClick={onOpen}
            />

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Customer
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                onClose()
                                deletadata(id, type)
                            }} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default IconButtonDelete