import React, { useState } from 'react'
import { Flex, Button, FormControl, FormLabel, Input, IconButton, Center, Spinner } from '@chakra-ui/react';
import axios from 'axios'
import { Formik, Form, Field, FieldArray } from 'formik';


import { AiFillDelete } from 'react-icons/ai';

const baseApi = process.env.REACT_APP_BASE_API;

function FormAddMore({ data, setStep, setCarId, setLoading, success_toast, error_toast }) {

    return (
        <Formik
            initialValues={{
                cars_subdetail: [
                    { name: '', value: '' },
                ]
            }}
            onSubmit={(values) => {
                setLoading(true)
                const asArray = Object.entries(values.cars_subdetail);
                const array_cars_subdetail = []
                const filtered = asArray.map(([key, value]) => {
                    if (value.name && value.value)
                        array_cars_subdetail.push(value);
                });
                if (data) {
                    const createCar = async () => {
                        try {
                            const formData = new FormData();
                            formData.append("image", data.cars_image);
                            formData.append("cars_title", data.cars_title);
                            formData.append("brand_name", data.brand_name);
                            formData.append("model_name", data.model_name);
                            formData.append("cars_detail", JSON.stringify(data.cars_detail));
                            formData.append("cars_subdetail", JSON.stringify(array_cars_subdetail));
                            await axios.post(`${baseApi}/api/admin/create/cars`,
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        Authorization: localStorage.getItem('access_token')
                                    }
                                }).then((resp) => {
                                    setLoading(false);
                                    setCarId(resp.data._id);
                                    setStep(2);
                                    success_toast();
                                })
                        } catch (error) {
                            setLoading(false);
                            error_toast();
                        }
                    }
                    createCar();
                }
            }
            }
            render={({ values }) => (
                <Form>
                    <FieldArray
                        name="cars_subdetail"
                        render={arrayHelpers => (
                            <Flex direction={'column'} gap={'24px'} pos={'relative'}>
                                {values.cars_subdetail && values.cars_subdetail.length > 0 && (
                                    values.cars_subdetail.map((item, index) => (
                                        <Flex key={index} align={'end'} gap={'12px'}>
                                            <FormControl>
                                                <FormLabel htmlFor={`cars_subdetail[${index}].name`}>
                                                    ชื่อ
                                                </FormLabel>
                                                <Input
                                                    as={Field}
                                                    id={`cars_subdetail[${index}].name`}
                                                    name={`cars_subdetail[${index}].name`}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel htmlFor={`cars_subdetail[${index}].value`}>
                                                    ข้อมูล
                                                </FormLabel>
                                                <Input
                                                    as={Field}
                                                    name={`cars_subdetail[${index}].value`}
                                                />
                                            </FormControl>

                                            <IconButton
                                                type="Button"
                                                aria-label="delete"
                                                icon={<AiFillDelete />}
                                                colorScheme="red"
                                                variant={'solid'}
                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                            />
                                        </Flex>
                                    ))
                                )}
                                <Button
                                    variant={'outline'}
                                    colorScheme={'green'}
                                    size={'sm'}
                                    type="Button"
                                    onClick={() => arrayHelpers.push('')}>
                                    เพิ่มข้อมูล
                                </Button>
                                <Flex justifyContent={'space-between'} >
                                    <Button
                                        variant={'solid'}
                                        size={'lg'}
                                        colorScheme={'teal'}
                                        minW={'120px'}
                                        type='Button'
                                        onClick={() => setStep(0)}
                                    >
                                        ย้อนกลับ
                                    </Button>
                                    <Button
                                        variant={'solid'}
                                        size={'lg'}
                                        colorScheme={'teal'}
                                        minW={'120px'}
                                        type='submit'
                                    >
                                        บันทึกข้อมูล
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                    />
                </Form>
            )}
        />
    )
}

export default FormAddMore