import { Select } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'

import useSWR from 'swr'


const baseapi = process.env.REACT_APP_BASE_API;

export default function Select_SubModel({ filter, ...props }) {
    console.log(filter);
    const [submodel, setSubmodel] = useState([])
    const fetcher = (url) => fetch(url).then((res) => res.json())
    const { data, error } = useSWR(`${baseapi}/api/store/models`, fetcher)
    // if (error) return <div>failed to load</div>
    // if (!data) return <div>loading...</div>
    console.log(data);
    useEffect(() => {
        if (data) {
            const model = data.filter(item => item.model_name === filter)
            console.log(model);
            setSubmodel(model[0]?.model_submodel)
        }
    }, [data, filter])

    console.log(submodel);

    return (
        <Select placeholder='Choose a model' _focus={{ outline: 'none' }} {...props} >
            {submodel?.map((submodel, idx) => (
                <option key={idx} value={submodel}>
                    {submodel}
                </option>
            ))}
        </Select>
    )
}
