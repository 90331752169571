import React from 'react'
import { BsUpload } from 'react-icons/bs'
import { FormLabel, } from '@chakra-ui/react'



function Imageupload({ target, ...restprops }) {



    return (

        <FormLabel
            htmlFor={target}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={2}
            border={'1px dashed '}
            borderColor={'gray.300'}
            rounded={'lg'}
            textAlign={'center'}
            color={'gray.600'}
            p={'12px'}
            cursor={'pointer'}
            m={0}
            _hover={{
                borderColor: 'gray.400',
                bg: 'blackAlpha.100',
            }}
            {...restprops}
        >
            <BsUpload  style={{
                color: 'var(--chakra-colors-gray-400)',
            }} />
            Upload your image here
        </FormLabel>
    )
}

export default Imageupload