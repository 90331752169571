import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import { useFormik } from "formik";
import axios from 'axios'

const baseApi = process.env.REACT_APP_BASE_API;

function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const [isloading, setIsloading] = useState(false)
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      const loginUser = async () => {
        try {
          const _login = await axios.post(`${baseApi}/api/user/login`,
            {
              username: values.username,
              password: values.password
            }
          ).then((resp) => {
            // console.log(resp);
            setIsloading(true)
            if (resp.data) {
              localStorage.setItem('access_token', resp?.data?.access_token);
              localStorage.setItem('refresh_token', resp?.data?.refresh_token);
              localStorage.setItem('user_name', resp?.data?.result?.username);
              localStorage.setItem('user_position', resp?.data?.result?.position);
              setTimeout(() => window.location.href = '/admin', 1000)
            }

          })
        } catch (error) {
          signinerror()
          // alert("ชื่อผู้ใช้งาน หรือ รหัสผ่าน ไม่ถูกต้อง");
        }
      }
      loginUser();
    }
  });
  const signinerror = useToast({
    title: 'เกิดข้อผิดพลาด',
    position: 'top',
    description: `ชื่อผู้ใช้งาน หรือ รหัสผ่าน ไม่ถูกต้อง`,
    status: 'error',
    duration: 5000,
    isClosable: true,
    containerStyle: {
      marginTop: '20px'
    }
  })
  return (
    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Nirun Dashboard
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              กรุณาระบุตัวตน เพื่อเข้าใช้งาน
            </Text>
            <form onSubmit={formik.handleSubmit}>
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  ชื่อผู้ใช้งาน
                </FormLabel>
                <Input
                  id='username'
                  name='username'
                  borderRadius='15px'
                  mb='24px'
                  fontSize='sm'
                  type='text'
                  placeholder='Your Username'
                  size='lg'
                  onChange={formik.handleChange}
                />
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  รหัสผ่าน
                </FormLabel>
                <Input
                  id='password'
                  name='password'
                  borderRadius='15px'
                  mb='36px'
                  fontSize='sm'
                  type='password'
                  placeholder='Your password'
                  size='lg'
                  onChange={formik.handleChange}
                />
                {/* <FormControl display='flex' alignItems='center'>
                <Switch id='remember-login' colorScheme='teal' me='10px' />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  ms='1'
                  fontWeight='normal'>
                  Remember me
                </FormLabel>
              </FormControl> */}
                <Button
                  type='submit'
                  bg='teal.300'
                  w='100%'
                  h='45'
                  mb='20px'
                  color='white'
                  mt='20px'
                  _hover={{
                    bg: "teal.200",
                  }}
                  fontSize={'md'}
                  _active={{
                    bg: "teal.400",
                  }}>
                  {
                    isloading ?
                      'loading...' :
                      'เข้าสู่ระบบ'
                  }
                </Button>
              </FormControl>
            </form>
            {/* <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Don't have an account?
                <Link color={titleColor} as='span' ms='5px' fontWeight='bold'>
                  Sign Up
                </Link>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'>
          <Box
            bgImage={"https://picsum.photos/id/0/5000/3333"}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
