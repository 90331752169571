import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { Flex, Button, FormControl, FormLabel, Input, InputGroup } from '@chakra-ui/react';
import axios from 'axios'
import Select_Brand from 'components/Select/Select_Brand';

const baseApi = process.env.REACT_APP_BASE_API;


function FormAddModel({ editdata, setData, onClose, brand, success_toast, error_toast, editsuccess_toast, editerror_toast, mutate }) {
    console.log(editdata);
    const formik = useFormik({
        initialValues: {
            brand_name: editdata?.brand_name ? editdata.brand_name : '',
            model_name: editdata?.model_name ? editdata.model_name : '',
        },
        onSubmit: (values) => {
            // console.log(values);
            if (!editdata) {
                try {
                    const createModel = async () => {
                        const _create = await axios.post(`${baseApi}/api/admin/create/model`,
                            values,
                            {
                                headers: {
                                    Authorization: localStorage.getItem('access_token')
                                }
                            }).then((resp) => {
                                if (resp.data) {
                                    success_toast()
                                    mutate()
                                    onClose();
                                }
                            })
                    }
                    createModel();
                } catch (error) {
                    alert(error)
                    error_toast()
                    mutate()
                }
            } else {
                try {
                    const updateModel = async (id) => {
                        const _update = await axios.post(`${baseApi}/api/admin/update/model/${id}`,
                            values,
                            {
                                headers: {
                                    Authorization: localStorage.getItem('access_token')
                                }
                            }).then((resp) => {
                                if (resp.data) {
                                    editsuccess_toast()
                                    mutate()
                                    onClose();
                                }
                            })
                    }
                    updateModel(editdata._id);
                } catch (error) {
                    alert(error)
                    editerror_toast()
                    mutate()
                }
            }
        },
    })
    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex flexDir={'column'} >
                <FormControl mt={4} >
                    <FormLabel htmlFor='brand_name' fontSize={'lg'}>ชื่อยี่ห้อ</FormLabel>
                    <InputGroup>
                        <Select_Brand
                            id='brand_name'
                            name='brand_name'
                            value={formik.values.brand_name}
                            onChange={formik.handleChange}
                        />
                        {/* <Input
                            id='brand_name'
                            name='brand_name'
                            type='text'
                            value={formik.values.brand_name}
                            onChange={formik.handleChange}
                        /> */}
                    </InputGroup>
                </FormControl>
                <FormControl mt={4} >
                    <FormLabel htmlFor='model_name' fontSize={'lg'}>ชื่อรุ่นรถ</FormLabel>
                    <InputGroup>
                        <Input
                            id='model_name'
                            name='model_name'
                            type='text'
                            value={formik.values.model_name}
                            onChange={formik.handleChange}
                        />
                    </InputGroup>
                </FormControl>
                <Flex mt={12} justifyContent={'end'} >
                    <Button
                        variant={'solid'}
                        colorScheme={'teal'}
                        minW={'120px'}
                        type='submit'
                        disabled={
                            formik.values.model_name === '' &&
                            true
                        }
                    >
                        {
                            editdata ? 'แก้ไข' : 'เพิ่ม'
                        }
                    </Button>
                </Flex>
            </Flex>
        </form>
    )
}

export default FormAddModel