// Chakra imports
import { Flex, Skeleton } from "@chakra-ui/react";
import React, { useState } from "react";
import Model from "./components/Model";
import { tablesTableData, dashboardTableData } from "variables/general";
import { model } from "../../../variables/carmodelmockup"

import { createColumnHelper } from "@tanstack/react-table";
import useSWR from 'swr'


const baseapi = process.env.REACT_APP_BASE_API

export default function Tables() {

  // const data = model
  const fetcher = (url) => fetch(url).then((res) => res.json())
  const { data, error, mutate } = useSWR(`${baseapi}/api/store/models`, fetcher);
  const [filterData, setFilterData] = useState('');
  if (error) return <div>failed to load</div>
  if (!data) return <Skeleton height='100%' width='100%' />

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("model_name", {
      cell: (info) => info.getValue(),
      header: "รุ่นรถ"
    }),
    columnHelper.accessor("brand_name", {
      cell: (info) => info.getValue(),
      header: "ยี่ห้อรถ"
    }),
  ];

  const modelFilter = (modelData) => {
    let filter = modelData.filter((ele, idx) => {
      return (ele.model_name?.toUpperCase().indexOf(filterData?.toUpperCase()) > -1)
    })
    return filter;
  }

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Model
        title={"รุ่นรถ"}
        columns={columns}
        data={modelFilter(data.sort((a, b) => {
          let fa = a.brand_name.toLowerCase(),
            fb = b.brand_name.toLowerCase();
          if (fb == 'honda') {
            return 1;
          }
          if (fb == 'toyota') {
            return 1;
          }
          if (fb == 'isuzu') {
            return 1;
          }
          if (fb == 'nissan') {
            return 1;
          }
          if (fb == 'mitsubishi') {
            return 1;
          }
          if (fb == 'mazda') {
            return 1;
          }
          if (fb == 'ford') {
            return 1;
          }
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }))}
        mutate={mutate}
        setFilterData={setFilterData}
      />
    </Flex>
  );
}


