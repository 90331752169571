import React from 'react'
import { Flex, Box, Heading, Image, Text } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'



export default function Specifications({ data, _id, ...props }) {
    const dataset = [
        {
            name: 'ยี่ห้อ',
            value: data.brand_name
        },
        {
            name: 'รุ่นรถ',
            value: data.model_name
        },
        {
            name: 'รุ่นย่อย',
            value: data.cars_detail.sub_model
        },
        {
            name: 'ปีรถ',
            value: data.cars_detail.year
        },
        {
            name: 'สถานะ',
            value: data.cars_status
        },
        {
            name: 'ประเภทรถ',
            value: data.cars_detail.car_type
        },
        {
            name: 'ประเภทย่อย',
            value: data.cars_detail.car_sub_type
        },
        {
            name: 'ประเภทเครื่องยนต์',
            value: data.cars_detail.engine_type
        },
        {
            name: 'เครื่องยนต์(CC)',
            value: data.cars_detail.engine_CC
        },
        {
            name: 'สีรถ',
            value: data.cars_detail.color
        },
        {
            name: 'เกียร์',
            value: data.cars_detail.gear
        },
        {
            name: 'ที่นั่ง',
            value: data.cars_detail.seat
        },
        {
            name: 'เลขไมล์',
            value: data.cars_detail.mile
        },
        {
            name: 'วันที่อัปเดต',
            value: data.updateDate
        },
        {
            name: 'วันที่สร้าง',
            value: data.createDate
        },
    ]
    return (
        <>
            <Card>
                <CardHeader>
                    <Heading>
                        {data.cars_title}
                    </Heading>
                </CardHeader>
                <CardBody flexDir={'column'} gap={'24px'}>
                    <Image
                        src={`${process.env.REACT_APP_S3_BASE_URI}/Default/${data.cars_image_default}`}
                        width={500}
                        alt={data.cars_image_default}
                        bg={'whiteAlpha.300'}
                    />
                    <Flex flexDir={'column'} border={'1px'} borderColor={'whiteAlpha.300'} borderRadius={'12px'} overflow={'hidden'}>
                        {dataset.map((item, index) => {
                            return (
                                <Flex key={index}
                                    py={'12px'}
                                    px={'24px'}
                                    bg={index % 2 == 0 ? 'transparent' : 'whiteAlpha.100'}
                                >
                                    <Text w={'150px'} flexShrink={0}  >
                                        {item.name}
                                    </Text>
                                    <Text w={'full'}>
                                        {item.value ? item.value : '-'}
                                    </Text>
                                </Flex>
                            )
                        }
                        )}
                    </Flex>
                </CardBody>
            </Card>
        </>
    )
}
