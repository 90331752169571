import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Button } from '@chakra-ui/react'
import Specifications from './components/Specifications'
import Moredetail from './components/Moredetail'
import Gallery from './components/Gallery'






export default function CarDetail() {
    const { _id } = useParams()
    const fetcher = (url) => fetch(url).then((res) => res.json())
    const { data, error } = useSWR(`${process.env.REACT_APP_BASE_API}/api/store/cars/${_id}`, fetcher)
    // console.log(data)
    if (error) return <div>failed to load</div>
    if (!data) return <div>loading...</div>
    return (
        <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
            <Flex justify={'end'} py={'24px'} >
                <Button
                    w={'fit-content'}
                    colorScheme='blue'
                    variant='outline'
                    onClick={() => window.location.href = `/#/form/editcar/${_id}`}
                >
                    แก้ไขข้อมูล
                </Button>
            </Flex>
            <Tabs isLazy variant='enclosed'>
                <TabList gap={'12px'}>
                    <Tab _focus={{ boxShadow: "none" }} >ข้อมูลจำเพาะ</Tab>
                    <Tab _focus={{ boxShadow: "none" }} >ข้อมูลเสริม</Tab>
                    <Tab _focus={{ boxShadow: "none" }} >รูปภาพ</Tab>
                    <Tab _focus={{ boxShadow: "none" }} >ความคิดเห็นผู้ขาย</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Specifications _id={_id} data={data} />
                    </TabPanel>
                    <TabPanel>
                        <Moredetail _id={_id} data={data.cars_subdetail} />
                    </TabPanel>
                    <TabPanel>
                        <Gallery _id={_id} data={data.cars_image} />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Flex>
    )
}
