import { Select } from '@chakra-ui/react'
import React from 'react'

export default function Select_Status({ ...props }) {
    const status = [
        { name: 'กำลังขาย', value: 'SELL' },
        { name: 'จองแล้ว', value: 'RESERVE' },
        { name: 'ขายแล้ว', value: 'SOLD' }
    ]
    return (
        <Select placeholder='Choose a Status' _focus={{ outline: 'none' }} {...props} >
            {status.map((item, idx) => (
                <option 
                key={idx} 
                value={item.value}
                >
                    {item.name}
                </option>
            ))}
        </Select>
    )
}
